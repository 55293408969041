import { useEffect, useState } from "react"

import { Http } from "@encoway/c-services-js-client"

import UserService from "../services/sso/userService"
import { initCatalogService } from "./catalogServiceHolder"
import { initi18next } from "./i18n"
import { initSalesService } from "./salesServiceHolder"

export const useInitApplication = () => {
    const [initialized, setInitialized] = useState(false)
    const [initializationError, setInitializationError] = useState(false)

    useEffect(() => {
        const initApp = async () => {
            try {
                // wait for user to be logged in
                await UserService.init()

                // Init sales service, if we are ready
                if (UserService.getTokenString()) {
                    const http = Http.Bearer(UserService.getTokenString)
                    // TODO: How to handle 403 or 401 responses?
                    await initSalesService(http)
                    await initCatalogService(http)
                    await initi18next()

                    // Replace authorization server URL params for react router
                    history.replaceState(null, "", "/")

                    setInitialized(true)
                }
            } catch (e) {
                setInitializationError(true)
                console.error("There is some error while init application", e)
            }
        }

        !initialized && initApp()
    }, [initialized])

    return { initialized, initializationError }
}
