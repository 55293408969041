import { IStackItemProps, Stack } from "@fluentui/react/lib/Stack"
import React, { useEffect, useState } from "react"

import { ParameterTO } from "@encoway/c-services-js-client"
import { ImageButtonProps } from "@encoway/cui-configurator-components"

import { useGetProductGroupsQuery } from "../../../../services/catalogAPI"
import { BillOfMaterials } from "./BillOfMaterials"
import { ProductGroups } from "./ProductGroups"
import { Products } from "./Products"
import { SearchInputField } from "./SearchInputField"

const determineProductGroupId = (parameterTO: ParameterTO) => {
    // workaround for COMPOSITION_ROOT_PRODUCT_GROUP does not exist on type 'object' message
    const viewPortProperties = parameterTO.viewPortProperties as {
        COMPOSITION_ROOT_PRODUCT_GROUP: string
    }
    // we need to extract id from {"id1234"}
    return viewPortProperties?.COMPOSITION_ROOT_PRODUCT_GROUP.slice(2, -2)
}

export const BBraunCatalogComposition = (props: ImageButtonProps) => {
    const { data: parameterTO } = props
    const defaultProductGroupId = determineProductGroupId(parameterTO)
    const { data: productGroups } = useGetProductGroupsQuery(
        defaultProductGroupId
    )
    const initialProductGroupId = productGroups
        ? productGroups[0].productGroupId
        : null
    const [selectedProductGroupId, setSelectedProductGroupId] = useState(
        initialProductGroupId
    )

    useEffect(() => {
        if (initialProductGroupId) {
            setSelectedProductGroupId(initialProductGroupId)
        }
    }, [initialProductGroupId])

    if (productGroups && selectedProductGroupId) {
        const outerColumnProps: IStackItemProps = {
            align: "stretch",
            grow: 1,
            style: {
                flexBasis: "18%",
                minWidth: "18%",
                maxWidth: "18%",
                overflow: "auto"
            }
        }
        return (
            <Stack
                horizontal
                verticalFill
                tokens={{ childrenGap: 40 }}
                style={{ marginTop: "3.75rem" }}
            >
                <Stack.Item {...outerColumnProps}>
                    <SearchInputField placeholder={"Search for article"} />
                    <ProductGroups
                        productGroups={productGroups}
                        selectedProductGroupId={selectedProductGroupId}
                        onProductGroupSelection={setSelectedProductGroupId}
                    />
                </Stack.Item>
                <Stack.Item
                    align="stretch"
                    grow={2}
                    style={{
                        flexBasis: "50%",
                        minWidth: "50%",
                        maxWidth: "50%"
                    }}
                >
                    <Products
                        {...props}
                        productGroupId={selectedProductGroupId}
                    />
                </Stack.Item>
                <Stack.Item
                    {...outerColumnProps}
                    {...{
                        style: {
                            flexBasis: "25%",
                            minWidth: "25%",
                            maxWidth: "25%"
                        }
                    }}
                >
                    <BillOfMaterials
                        {...props}
                        productGroupId={defaultProductGroupId}
                    />
                </Stack.Item>
            </Stack>
        )
    } else {
        return <></>
    }
}
