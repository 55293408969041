import * as React from "react"
import { useEffect } from "react"

import { ContainerTO, ParameterTO } from "@encoway/c-services-js-client"
import { Tabs, TabsProps } from "@encoway/cui-configurator-components"

import { useAppDispatch } from "../../../app/hooks"
import { setComponentsAndAccessories } from "../../../features/set/setSlice"
import { customColors } from "../../themes/styles"
import { useCatalogCompositionBomHelper } from "./catalogComposition/catalogComposition.hooks"

export const BBraunTabs = (props: TabsProps) => {
    const { getBomProducts } = useCatalogCompositionBomHelper(null)
    const dispatch = useAppDispatch()

    const iterateOverChildren = (children: ContainerTO[]) =>
        children.forEach((child) => determineComponentsAndAccessories(child))
    const iterateOverParameter = (parameter: ParameterTO[]) =>
        parameter.forEach(
            (parameter) =>
                parameter.viewPort === "bbraunCatalogComposition" &&
                determineBom(parameter)
        )
    const determineBom = (parameter: ParameterTO) =>
        dispatch(setComponentsAndAccessories(getBomProducts(parameter)))
    const determineComponentsAndAccessories = (child: ContainerTO) => {
        iterateOverChildren(child.children)
        iterateOverParameter(child.parameters)
    }
    useEffect(() => {
        determineComponentsAndAccessories(props.data)
    }, [])

    return (
        <Tabs
            {...props}
            styles={{
                tabsBar: {
                    margin: "0 1.75rem",
                    borderBottom: `1px solid ${customColors.lightGray}`,
                    minHeight: "3rem"
                }
            }}
        />
    )
}
