import {
    Button,
    ButtonProps,
    DialogActions,
    DialogContent,
    DialogContentText
} from "@mui/material"
import MuiDialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle/DialogTitle"
import React from "react"

export type DialogButtonProps = ButtonProps & {
    text: string
    onClick: () => void
}

type Props = {
    open: boolean
    onClose: () => void
    title: string
    message: string
    buttons?: DialogButtonProps[]
}

export const Dialog = ({ open, onClose, title, message, buttons }: Props) => {
    return (
        <MuiDialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {buttons &&
                    buttons.map((button, index) => {
                        const key = `Buttons-${index}`
                        return (
                            <Button {...button} key={key}>
                                {button.text}
                            </Button>
                        )
                    })}
            </DialogActions>
        </MuiDialog>
    )
}
