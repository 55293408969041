import Card from "@mui/material/Card"
import CardActionArea from "@mui/material/CardActionArea"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React from "react"

import { Product } from "@encoway/sales-api-js-client"

import { newConfigurationDialogStyles } from "./newConfigurationDialog.styles"

type Props = {
    product: Product
    onSelect: () => void
}

export const ConfigurableProduct = ({ product, onSelect }: Props) => {
    const { characteristics, productId } = product
    const label = characteristics?.label
    const imageUrl =
        characteristics &&
        characteristics.product_image &&
        characteristics.product_image.length &&
        characteristics.product_image[0].uri
    const longText = characteristics?.long_text_gui ?? ""

    return (
        <Grid
            item
            key={productId}
            sx={newConfigurationDialogStyles.templatesItem}
        >
            <Card elevation={2} sx={newConfigurationDialogStyles.templatesCard}>
                <CardActionArea
                    sx={newConfigurationDialogStyles.templatesCardInner}
                    onClick={onSelect}
                >
                    {imageUrl && (
                        <CardMedia
                            sx={newConfigurationDialogStyles.templatesCardMedia}
                            component="img"
                            height="auto"
                            image={`/${imageUrl}`}
                            alt={label}
                        />
                    )}
                    <CardContent
                        sx={newConfigurationDialogStyles.templatesCardContent}
                    >
                        <Typography
                            gutterBottom
                            variant="h6"
                            component="div"
                            fontWeight="bold"
                        >
                            {label}
                        </Typography>
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            dangerouslySetInnerHTML={{
                                __html: longText
                            }}
                        ></Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
}
