import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { createApi } from "@reduxjs/toolkit/query/react"

import {
    Paging,
    SalesDocumentEntity,
    SalesDocumentFilter,
    SalesDocumentSelection
} from "@encoway/sales-api-js-client"

import { getSalesService } from "../app/salesServiceHolder"
import { SetChanges } from "../features/set/setSlice"
import {
    ListOptions,
    SalesDocument,
    SalesDocuments
} from "../features/sets/sets.types"

const handleApiError = (error: any) => {
    console.error("There was an error in salesService", error)
}

export const salesDocumentAPI = createApi({
    reducerPath: "salesDocumentAPI",
    baseQuery: fetchBaseQuery(), // we don't need the base query right now, that's why it's empty
    tagTypes: ["SalesDocuments", "SalesDocument"],
    endpoints: (builder) => ({
        getAllSalesDocuments: builder.query<SalesDocuments, ListOptions>({
            async queryFn(listOptions) {
                const { page, rowsPerPage, sortField, sortDirection, filter } =
                    listOptions

                const paging = new Paging()
                    .offset(page * rowsPerPage)
                    .limit(rowsPerPage)
                    .sortField(sortField)
                    .sortDir(sortDirection)

                const selection = filter
                    ? new SalesDocumentSelection().filterConfig(filter)
                    : undefined

                const sets = await getSalesService().salesDocuments.get(
                    selection,
                    paging
                )
                return { data: sets }
            },
            providesTags: ["SalesDocuments"]
        }),
        openSalesDocument: builder.mutation<SalesDocument, string>({
            async queryFn(quoteId) {
                const filter = new SalesDocumentFilter().add(
                    "quote_id",
                    "=",
                    quoteId,
                    "string"
                )
                const salesDocuments =
                    await getSalesService().salesDocuments.get(
                        new SalesDocumentSelection().filterConfig(filter)
                    )

                const setId = salesDocuments.data[0].salesDocumentId
                const salesDocument = await getSalesService()
                    .salesDocuments.open(setId, true)
                    .catch(handleApiError)

                return { data: salesDocument }
            },
            invalidatesTags: ["SalesDocument"]
        }),
        getCurrentSalesDocument: builder.query<SalesDocumentEntity, void>({
            async queryFn() {
                const salesDocument: SalesDocument = (await getSalesService()
                    .salesDocument.get()
                    .catch(handleApiError)) as SalesDocumentEntity

                return { data: salesDocument }
            },
            providesTags: ["SalesDocument"]
        }),
        createSalesDocument: builder.mutation<SalesDocument, void>({
            async queryFn() {
                const salesDocument = await getSalesService()
                    .salesDocuments.create(null)
                    .catch(handleApiError)

                return { data: salesDocument }
            },
            invalidatesTags: ["SalesDocument"]
        }),
        updateSalesDocument: builder.mutation<void, SetChanges>({
            async queryFn(changes) {
                await getSalesService()
                    .salesDocument.update(changes, {})
                    .catch(handleApiError)

                return { data: undefined as void }
            },
            invalidatesTags: ["SalesDocument"]
        }),
        saveSalesDocument: builder.mutation<SalesDocumentEntity, SetChanges>({
            async queryFn(changes) {
                await getSalesService()
                    .salesDocument.update(changes, {})
                    .catch(handleApiError)

                const salesDocument = (await getSalesService()
                    .salesDocument.save()
                    .catch(handleApiError)) as SalesDocumentEntity

                return { data: salesDocument }
            },
            invalidatesTags: ["SalesDocuments", "SalesDocument"]
        }),
        duplicateSalesDocument: builder.mutation<SalesDocumentEntity, string>({
            async queryFn(setId) {
                const copy = await getSalesService()
                    .salesDocuments.copy(setId)
                    .catch(handleApiError)

                await getSalesService()
                    .salesDocument.save()
                    .catch(handleApiError)

                return { data: copy }
            },
            invalidatesTags: ["SalesDocuments"]
        }),
        deleteSalesDocument: builder.mutation<SalesDocumentEntity, string>({
            async queryFn(setId) {
                const deletedSalesDocument = await getSalesService()
                    .salesDocuments.delete(setId)
                    .catch(handleApiError)

                return { data: deletedSalesDocument }
            },
            invalidatesTags: ["SalesDocuments"]
        }),
        closeCurrentSalesDocument: builder.mutation<SalesDocument, void>({
            /*
             * Workaround: There is no certain endpoint to close a SalesDocument
             * as opposite of opening. So we need to create a new quote on server
             * side and update the local SalesDocument
             */
            async queryFn() {
                const salesDocument = await getSalesService()
                    .salesDocuments.create(null)
                    .catch(handleApiError)

                return { data: salesDocument }
            },
            invalidatesTags: ["SalesDocument"]
        })
    })
})

export const {
    useGetAllSalesDocumentsQuery,
    useOpenSalesDocumentMutation,
    useCreateSalesDocumentMutation,
    useSaveSalesDocumentMutation,
    useDuplicateSalesDocumentMutation,
    useDeleteSalesDocumentMutation,
    useUpdateSalesDocumentMutation,
    useGetCurrentSalesDocumentQuery,
    useCloseCurrentSalesDocumentMutation
} = salesDocumentAPI
