const newConfigurationDialogStyles = {
    selectReferenceWrapper: {
        display: "flex",

        "> button, > a ": {
            mr: 1,
            ml: 1,
            mt: 2,
            mb: 1
        },
        "> *:last-child": {
            mr: {
                md: 0
            }
        }
    },
    selectReferenceButton: {
        ml: 1,
        marginTop: "8px",
        paddingTop: "6px",
        paddingBottom: "8px"
    },
    section: {
        "&:not(:first-of-type)": {
            mt: 2,
            pt: 2,
            borderTopColor: "divider",
            borderTopWidth: "1px",
            borderTopStyle: "solid"
        }
    },
    templatesWrapper: { mt: 2, mb: 2 },
    templatesItem: { flex: "1 0 450px", maxWidth: { xs: "100%", md: "50%" } },
    templatesCard: { width: "100%", height: "100%" },
    templatesCardInner: {
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        height: "100%"
    },
    templatesCardMedia: {
        flex: "0 0 33.333%",
        width: "33.333%",
        height: "100%"
    },
    templatesCardContent: { flex: "auto", height: "100%" }
}

export { newConfigurationDialogStyles }
