import Stack from "@mui/material/Stack"
import Switch from "@mui/material/Switch"
import Typography from "@mui/material/Typography"
import React from "react"

import { ConnectorType } from "../sets/sets.types"

type Props = {
    connectorType: ConnectorType
    setConnectorType: (type: ConnectorType) => void
}
export const ConnectorTypeSwitch = ({
    connectorType,
    setConnectorType
}: Props) => {
    return (
        <>
            <Typography>Or Select Area To Configure At Set</Typography>
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ mt: 2, mb: 2 }}
            >
                <Typography
                    onClick={() => {
                        setConnectorType("LUER")
                    }}
                >
                    LUER
                </Typography>
                <Switch
                    name="type"
                    checked={connectorType === "NRFit"}
                    onChange={(event) => {
                        setConnectorType(
                            event.target.checked ? "NRFit" : "LUER"
                        )
                    }}
                />
                <Typography
                    onClick={() => {
                        setConnectorType("NRFit")
                    }}
                >
                    NRFit
                </Typography>
            </Stack>
        </>
    )
}
