import i18next, { TFunction } from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import HttpApi from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

export const initi18next = (): Promise<TFunction> =>
    i18next
        .use(HttpApi)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            load: "currentOnly",
            fallbackLng: false,
            debug: true,
            detection: { order: ["navigator"] },
            interpolation: {
                escapeValue: false
            },
            backend: {
                loadPath: `${location.protocol}//${location.host}/api/translation/{{lng}}`,
                allowMultiLoading: false,
                crossDomain: false
            }
        })
