import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { t } from "i18next"
import moment, { Moment } from "moment"
import React from "react"

import { DatePicker } from "../../../../common/FormFields/DatePicker"
import { InputField } from "../../../../common/FormFields/InputField"
import { SelectField } from "../../../../common/FormFields/SelectField"
import { SalesDocument } from "../../../sets/sets.types"
import { useProperties } from "./generalData.hook"
import { generalDataStyles } from "./generalDataStyles"

type Props = {
    set: SalesDocument
}

export const KitInformation = ({ set }: Props) => {
    const { setPropertyValue, selectPropertyValue } = useProperties(set)

    return (
        <Box sx={generalDataStyles.section}>
            <Typography component="h3" variant="h5">
                Kit information
            </Typography>
            <Grid
                container
                spacing={2}
                columns={{ xs: 2, md: 5 }}
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={2} md={2}>
                    <InputField
                        id="name"
                        label="Description"
                        required={true}
                        value={selectPropertyValue("name")}
                        onChange={(event) =>
                            setPropertyValue("name", event.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={1} md={1}>
                    <SelectField
                        id="articleRequest"
                        label="Article Request"
                        menuItems={["create a new set", "change of set"]}
                        value={selectPropertyValue("articleRequest")}
                        onChange={(event) =>
                            setPropertyValue(
                                "articleRequest",
                                event.target.value as string
                            )
                        }
                    />
                </Grid>
                <Grid item xs={1} md={1}>
                    <SelectField
                        id="quote_status"
                        label="Status"
                        menuItems={["Active", "Inactive", "In Progress"]}
                        value={selectPropertyValue("quote_status")}
                        onChange={(event) =>
                            setPropertyValue(
                                "quote_status",
                                event.target.value as string
                            )
                        }
                    />
                </Grid>
                <Grid item xs={1} md={1}>
                    <InputField
                        id="batchSize"
                        label="Batch Size"
                        value={selectPropertyValue("batchSize")}
                        onChange={(event) =>
                            setPropertyValue("batchSize", event.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={1} md={1}>
                    <InputField
                        id="articleNo"
                        label="Article Number"
                        value={selectPropertyValue("articleNo")}
                        onChange={(event) =>
                            setPropertyValue("articleNo", event.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={1} md={1}>
                    <DatePicker
                        label="Date"
                        value={moment(selectPropertyValue("date"))}
                        onChange={(moment: Moment) => {
                            if (moment) {
                                const format = "MM/DD/YYYY"
                                setPropertyValue("date", moment.format(format))
                            }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Grid>
                <Grid item xs={2} md={2}>
                    <InputField
                        id="drawingId"
                        label={t("application.common.drawingId")}
                        disabled={true}
                        value={set.properties.quote_id}
                    />
                </Grid>
                <Grid item xs={2} md={2}>
                    <InputField
                        id="referenceStandard"
                        label="Reference Standard"
                        disabled={true}
                        value={selectPropertyValue("referenceStandard")}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
