import { Http } from "@encoway/c-services-js-client"

import {
    PkceInformation,
    RefreshToken,
    TokenResponse
} from "./authenticationClient.types"

const PROPERTY_URL = "/api/oauth2/pkce/public"
const GET_ACCESS_TOKEN_URL = "/api/oauth2/token/public"
const UPDATE_TOKEN_URL = "/api/oauth2/token/public/refresh"

const PKCE_AUTH_STATE_KEY = "pkceAuthState"

const SESSION_STATE_URI_PARAM = "session_state"
const CODE_URI_PARAM = "code"

const PLAIN_HTTP = Http.Plain()

/**
 * Client implementation of the CPQ authentication API based on the workflow. Refer to
 * http://docs.encowayhb.lokal/files/CPQ/latest/CPQ%20Showroom/User%20Guide%20encoway%20OAuth%202.0%20Authentication%20API_en.html
 * for details.
 */

const getAuthenticationProperties = async (): Promise<PkceInformation> => {
    const response = await PLAIN_HTTP.get(PROPERTY_URL, {})
    const result: PkceInformation = await response.json()
    sessionStorage.setItem(PKCE_AUTH_STATE_KEY, result.authorization_state)
    return result
}

const redirectToAuthorizationServer = (properties: PkceInformation): void => {
    const redirectUrlWithParams = new URL(window.sso.authorizationEndpointUrl)
    // Add properties to URL
    Object.entries(properties).forEach(([k, v]) =>
        redirectUrlWithParams.searchParams.append(k, v)
    )

    // Add additional properties
    redirectUrlWithParams.searchParams.append("client_id", window.sso.clientId)
    redirectUrlWithParams.searchParams.append(
        "redirect_uri",
        window.sso.redirectUrl
    )

    // Redirect to auth server
    window.location.replace(redirectUrlWithParams.href)
}

const getAuthServerState = (): string | null => {
    const url = new URL(window.location.href)
    return url.searchParams.get(SESSION_STATE_URI_PARAM)
}
/**
 * Requests the access token from the CPQ backend.
 */
const getAccessToken = (): Promise<TokenResponse | null> => {
    // Get and remove pkce auth state key
    const authState = sessionStorage.getItem(PKCE_AUTH_STATE_KEY)
    sessionStorage.removeItem(PKCE_AUTH_STATE_KEY)

    const oneTimeCode = getOneTimeCode()
    if (!authState || !oneTimeCode) {
        console.error(
            "Could not get the AccessToken from CPQ backend, because I have no state or code about the authentication"
        )
        return Promise.resolve(null)
    }
    return PLAIN_HTTP.json(
        `${GET_ACCESS_TOKEN_URL}?state=${authState}&code=${oneTimeCode}`,
        {}
    )
}
/**
 * Calls the refresh token endpoint in the CPQ backend.
 */
const refreshToken = (token: RefreshToken): Promise<TokenResponse> => {
    return Http.Bearer(token.token_value).json(UPDATE_TOKEN_URL, {
        method: "POST"
    })
}
/**
 * Returns the one time code that was provided from the authorization server.
 */
const getOneTimeCode = (): string | null => {
    const url = new URL(window.location.href)
    return url.searchParams.get(CODE_URI_PARAM)
}

const AuthenticationClient = {
    getAuthenticationProperties,
    redirectToAuthorizationServer,
    getAuthServerState,
    getAccessToken,
    refreshToken
}
export default AuthenticationClient
