import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { InputField } from "../../../../common/FormFields/InputField"
import { SelectField } from "../../../../common/FormFields/SelectField"
import { SalesDocument } from "../../../sets/sets.types"
import { setChanges } from "../../setSlice"
import { useProperties } from "./generalData.hook"
import { generalDataStyles } from "./generalDataStyles"
import { useBudiProperties } from "./regulatoryData.hooks"

type Props = {
    set: SalesDocument
}

export const RegulatoryData = ({ set }: Props) => {
    const { selectPropertyValue } = useProperties(set)
    const {
        riskClass,
        intendedUsages,
        setIntendedUsage,
        intendedUsage,
        budiName
    } = useBudiProperties(set)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    return (
        <Box sx={generalDataStyles.section}>
            <Typography component="h3" variant="h5">
                Regulatory Data
            </Typography>
            <Grid
                container
                spacing={2}
                columns={{ xs: 2, md: 5 }}
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={1} md={1}>
                    <InputField
                        id="maximumRiskClass"
                        label="Maximum Risk Class"
                        disabled={true}
                        value={riskClass}
                    />
                </Grid>
                <Grid item xs={2} md={2}>
                    <SelectField
                        id="intendedUse"
                        label="Intended Use/ Specific Medical Purpose (MDR)"
                        menuItems={intendedUsages}
                        value={intendedUsage}
                        onChange={async (event) => {
                            const value: string = event.target.value as string
                            await setIntendedUsage(value)
                            dispatch(
                                setChanges({
                                    propertyId: "intendedUse",
                                    propertyValue: value
                                })
                            )
                        }}
                        disabled={intendedUsages.length < 2}
                    />
                </Grid>
                <Grid item xs={1} md={2}>
                    <InputField
                        id="budiName"
                        label="BUDI Name"
                        value={budiName}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={1} md={1}>
                    <InputField
                        id="additionalWarning"
                        label="Additional Contraindication/Warning"
                        disabled={true}
                        value={t(selectPropertyValue("additionalWarning"))}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
