import BuildIcon from "@mui/icons-material/Build"
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import { Chip } from "@mui/material"
import Box from "@mui/material/Box"
import { SxProps } from "@mui/system"
import React from "react"

import { StatusIconStyles } from "./StatusIcon.styles"

interface ComponentInterface {
    status: string | undefined
    withLabel?: boolean
    sx?: SxProps
}

export default function Component({
    status,
    withLabel,
    sx
}: ComponentInterface) {
    let icon

    let styles: SxProps = StatusIconStyles.wrapper
    let color:
        | "default"
        | "success"
        | "warning"
        | "primary"
        | "secondary"
        | "error"
        | "info"
        | undefined

    if (sx) {
        styles = { ...styles, ...sx }
    }

    switch (status) {
        case "active":
        case "complete":
            icon = <CheckCircleOutlinedIcon fontSize="small" />
            color = "success"
            break
        case "inactive":
        case "incomplete":
            icon = <ErrorOutlineOutlinedIcon fontSize="small" />
            color = "warning"
            break
        default:
            icon = <BuildIcon fontSize="small" />
            break
    }

    if (withLabel === true) {
        return (
            <Chip
                size="small"
                color={color}
                icon={icon}
                label={`${status || "unknown"}`}
            />
        )
    }

    return (
        <Box
            component="span"
            sx={styles}
            className={`is--${status || "unknown"}`}
        >
            {icon}
        </Box>
    )
}
