import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import Popover from "@mui/material/Popover"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import React from "react"

import { NotesButtonStyles } from "./NotesButton.styles"

interface ComponentInterface {
    note: string | undefined
    disabled?: boolean | undefined
    displayType?: string
}

export default function Component({
    note,
    disabled,
    displayType
}: ComponentInterface) {
    const [popupAnchor, setPopupAnchor] = React.useState<HTMLElement | null>(
        null
    )

    const [editingNote, setEditingNote] = React.useState(note)

    const popupIsOpen = Boolean(popupAnchor)
    const popupId = popupIsOpen ? "simple-popover" : undefined

    const isDisabled = disabled

    const openPopup = (anchorEl: HTMLElement) => {
        if (anchorEl && !isDisabled) {
            setPopupAnchor(anchorEl)
            anchorEl.classList.add("has--focus")
        }
    }

    const openPopupByButton = (event: React.MouseEvent<HTMLElement>) => {
        const currentAnchorEl = event.currentTarget
        return openPopup(currentAnchorEl)
    }

    const openPopupBySvg = (event: React.MouseEvent<SVGSVGElement>) => {
        const currentAnchorEl = event.currentTarget
        if (currentAnchorEl) {
            const parentButton = currentAnchorEl.parentElement
            if (parentButton) {
                return openPopup(parentButton)
            }
        }
        return null
    }

    const closePopup = () => {
        const currentAnchorEl = popupAnchor
        if (currentAnchorEl) {
            currentAnchorEl.classList.remove("has--focus")
        }

        setPopupAnchor(null)
    }

    const popupOnClose = () => {
        closePopup()
        saveNote()
    }

    const saveNote = () => {
        console.log(`Update note to ”${editingNote}“.`)
        closePopup()
    }

    const getButton = () => {
        let button

        if (displayType === "button") {
            button = (
                <Button
                    sx={NotesButtonStyles.trigger}
                    variant="text"
                    disabled={isDisabled}
                    endIcon={<SpeakerNotesIcon />}
                    onClick={openPopupByButton}
                >
                    Documents
                </Button>
            )
        } else {
            button = (
                <SpeakerNotesIcon
                    sx={NotesButtonStyles.trigger}
                    color={editingNote ? "primary" : "disabled"}
                    className={isDisabled ? "is--disabled" : ""}
                    onClick={openPopupBySvg}
                />
            )
        }

        if (isDisabled) {
            if (displayType === "button") {
                return (
                    <Tooltip
                        arrow
                        title="Documents can only be generated for complete configurations."
                    >
                        <Box component="div" sx={NotesButtonStyles.trigger}>
                            {button}
                        </Box>
                    </Tooltip>
                )
            } else {
                return (
                    <Tooltip
                        arrow
                        title="Documents can only be generated for complete configurations."
                    >
                        {button}
                    </Tooltip>
                )
            }
        } else {
            return button
        }
    }

    return (
        <>
            {getButton()}
            <Popover
                id={popupId}
                open={popupIsOpen}
                onClose={popupOnClose}
                anchorEl={popupAnchor}
                // onClose={popupAnchor}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
            >
                <Box sx={NotesButtonStyles.popup}>
                    <FormControl
                        sx={NotesButtonStyles.formControl}
                        size="small"
                        margin="normal"
                    >
                        <TextField
                            id="note"
                            label="Note"
                            multiline
                            rows={4}
                            value={editingNote}
                            onChange={(event) =>
                                setEditingNote(event.currentTarget.value)
                            }
                        />
                    </FormControl>
                </Box>
            </Popover>
        </>
    )
}
