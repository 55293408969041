import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"

import {
    Characteristic,
    Constraints,
    Paging,
    Product,
    ProductFilter,
    ProductGroup
} from "@encoway/sales-api-js-client"

import { getSalesService } from "../app/salesServiceHolder"

type ProductsQueryOptions = {
    productGroupId: string
    includeChildGroups?: boolean
    constraints?: Constraints[]
    paging?: Paging
}

export const createProductFilter = (
    includeChildGroups: boolean,
    constraints: Constraints[]
) => {
    let productFilter = new ProductFilter().includeChildGroups(
        includeChildGroups
    )
    constraints.forEach((constraint) => {
        productFilter = productFilter.constraint(
            constraint.characteristicId,
            constraint.operator,
            constraint.value
        )
    })
    return productFilter
}

export const createDefaultProductPaging = () => new Paging().limit(1000)

export const catalogAPI = createApi({
    reducerPath: "catalogAPI",
    baseQuery: fetchBaseQuery(), // we don't need the base query right now, that's why it's empty
    tagTypes: ["ProductGroups"],
    endpoints: (builder) => ({
        getProductGroups: builder.query<ProductGroup[], string>({
            async queryFn(parentProductGroupId) {
                const productGroups =
                    await getSalesService().masterData.productGroups(
                        parentProductGroupId
                    )
                return { data: productGroups }
            },
            providesTags: ["ProductGroups"]
        }),
        getProducts: builder.query<Product[], ProductsQueryOptions>({
            async queryFn(productsQueryOptions) {
                const products = await getSalesService()
                    .masterData.products(
                        productsQueryOptions.productGroupId,
                        createProductFilter(
                            productsQueryOptions.includeChildGroups ?? true,
                            productsQueryOptions.constraints ?? []
                        ),
                        productsQueryOptions.paging ??
                            createDefaultProductPaging()
                    )
                    .then((value) => value.data)
                return { data: products }
            },
            keepUnusedDataFor: 0
        }),
        getProductGroupFilterCharacteristics: builder.query<
            Characteristic[],
            string
        >({
            async queryFn(productGroupId) {
                const filterCharacteristics =
                    await getSalesService().masterData.filterCharacteristics(
                        productGroupId
                    )
                return { data: filterCharacteristics }
            },
            keepUnusedDataFor: 0
        })
    })
})

export const {
    useGetProductGroupsQuery,
    useGetProductsQuery,
    useGetProductGroupFilterCharacteristicsQuery
} = catalogAPI
