import {
    ComponentName as ApplicationComponentName,
    registerDefaultApplicationComponents
} from "@encoway/cui-application-components"
import {
    ComponentName as ConfiguratorComponentName,
    registerDefaultConfiguratorComponents
} from "@encoway/cui-configurator-components"

import { BBraunBusyOverlay } from "./components/configurator/BBraunBusyOverlay"
import { BBraunImageButton } from "./components/configurator/BBraunImageButton"
import { BBraunInfoButton } from "./components/configurator/BBraunInfoButton"
import { BBraunInputField } from "./components/configurator/BBraunInputField"
import { BBraunParameter } from "./components/configurator/BBraunParameter"
import { BBraunTab } from "./components/configurator/BBraunTab"
import { BBraunTabs } from "./components/configurator/BBraunTabs"
import { BBraunTabsFooter } from "./components/configurator/BBraunTabsFooter"
import { BBraunTextButton } from "./components/configurator/BBraunTextButton"
import { BBraunCatalogComposition } from "./components/configurator/catalogComposition/BBraunCatalogComposition"

export const initComponents = () => {
    registerDefaultApplicationComponents().override(
        ApplicationComponentName.Header,
        () => null
    )

    registerDefaultConfiguratorComponents()
        .override("bbraunCatalogComposition", BBraunCatalogComposition)
        .override(ConfiguratorComponentName.ImageButton, BBraunImageButton)
        .override(ConfiguratorComponentName.InfoButton, BBraunInfoButton)
        .override(ConfiguratorComponentName.InputField, BBraunInputField)
        .override(ConfiguratorComponentName.NotReadyCount, () => null)
        .override(ConfiguratorComponentName.Parameter, BBraunParameter)
        .override(ConfiguratorComponentName.StateIcon, () => null)
        .override(ConfiguratorComponentName.Tab, BBraunTab)
        .override(ConfiguratorComponentName.TabButton, () => null)
        .override(ConfiguratorComponentName.Tabs, BBraunTabs)
        .override(ConfiguratorComponentName.TabsFooter, BBraunTabsFooter)
        .override(ConfiguratorComponentName.TextButton, BBraunTextButton)
        .override(ApplicationComponentName.BusyOverlay, BBraunBusyOverlay)
}
