import AuthenticationClient from "./authenticationClient"
import { TokenResponse } from "./authenticationClient.types"

const REFRESH_OFFSET_MS = 30 * 1000
const TOKEN_STORAGE = "encowayToken"
let refreshIntervalId: number
/**
 * Initialize the login of the user on the Authorization-Server
 */
const init = async () => {
    // Check if the user is redirect from Authorization-Server
    const sessionState = AuthenticationClient.getAuthServerState()
    if (!sessionState) {
        // Init login on CPQ-Backend and redirect user for login to Authorization-Server
        const props = await AuthenticationClient.getAuthenticationProperties()
        AuthenticationClient.redirectToAuthorizationServer(props)
    } else {
        /*
         * The user is coming back from Authorization-Server after a successful login.
         * Request and store the AccessToken
         */
        const token = await AuthenticationClient.getAccessToken()

        if (token) {
            saveAccessToken(token)
            initRefreshTokenHandler(calculateRefreshIntervalInMS(token))
        } else {
            console.error("Login failed. If have no AccessToken")
        }
    }
}
/**
 * Returns the current access token from the session storage.
 */
const getAccessToken = (): TokenResponse => {
    const token = window.sessionStorage.getItem(TOKEN_STORAGE)
    return token && JSON.parse(token)
}
/**
 * Requests a new access token to replace the current one.
 */
const refreshToken = async () => {
    const token = await AuthenticationClient.refreshToken(
        getAccessToken().refresh_token
    )
    saveAccessToken(token)
}
/**
 * Saves the given access token in the session storage.
 * @param token
 */
const saveAccessToken = (token: TokenResponse) => {
    window.sessionStorage.setItem(TOKEN_STORAGE, JSON.stringify(token))
}
/**
 * Returns the token string of the access token to use for API calls as Bearer-Token
 */
const getTokenString = (): string => {
    const token = getAccessToken()
    return token && token.access_token.token_value
}
/**
 * Initializes a handler to refresh the access token automatically.
 * @param intervalInMS Refresh interval in milliseconds
 */
const initRefreshTokenHandler = (intervalInMS: number) => {
    refreshIntervalId = window.setInterval(refreshToken, intervalInMS)
}
/**
 * Removed the handler to refresh the access token.
 */
const removeRefreshTokenHandler = () => {
    refreshIntervalId && window.clearInterval(refreshIntervalId)
}
/**
 * Calculate to refresh interval based on the token expire date
 * We use a 30sec margin to refresh the token not to edgy
 */
const calculateRefreshIntervalInMS = (token: TokenResponse) => {
    const tokenExpires = token.access_token.expires_at
    const now = new Date().getTime()
    // This will produce a date from 1970, but with the interval in ms we need to refresh the token
    return new Date(tokenExpires - now - REFRESH_OFFSET_MS).getTime()
}
/**
 * Handler to end a user-session on the Authorization-Server and to clear
 * all local authorization information.
 */
const logout = (): void => {
    removeRefreshTokenHandler()
    // Remove token in storage
    window.sessionStorage.removeItem(TOKEN_STORAGE)
}

const UserService = {
    init,
    getTokenString,
    logout
}

export default UserService
