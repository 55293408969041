import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React from "react"

import { InputField } from "../../../../common/FormFields/InputField"
import { SalesDocument } from "../../../sets/sets.types"
import { useProperties } from "./generalData.hook"
import { generalDataStyles } from "./generalDataStyles"

type Props = {
    set: SalesDocument
}

export const CustomerInformation = ({ set }: Props) => {
    const { setPropertyValue, selectPropertyValue } = useProperties(set)
    return (
        <Box sx={generalDataStyles.section}>
            <Typography component="h3" variant="h5">
                Customer Information
            </Typography>
            <Grid
                container
                spacing={2}
                columns={{ xs: 2, md: 5 }}
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={2} md={2}>
                    <InputField
                        id="customer"
                        label="Customer"
                        value={selectPropertyValue("customer")}
                        onChange={(event) =>
                            setPropertyValue("customer", event.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={1} md={1}>
                    <InputField
                        id="country"
                        label="Country of Sale"
                        value={selectPropertyValue("country")}
                        onChange={(event) =>
                            setPropertyValue("country", event.target.value)
                        }
                    />
                </Grid>
                <Grid item xs={1} md={1}>
                    <InputField
                        id="salesRep"
                        label="Sales Rep"
                        value={selectPropertyValue("salesRep")}
                        onChange={(event) =>
                            setPropertyValue("salesRep", event.target.value)
                        }
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
