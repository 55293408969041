import React, { ForwardedRef, useEffect } from "react"

import {
    BusyOverlayHandle,
    BusyOverlayProps
} from "@encoway/cui-application-components"

import { LoadingLayer } from "../../../common/LoadingLayer/LoadingLayer"

function preventInput(event: Event) {
    event.preventDefault()
    event.stopPropagation()
}

const LISTENER_TYPES = ["keydown", "keypress", "keyup"]

export const BBraunBusyOverlay: React.FunctionComponent<BusyOverlayProps> =
    React.forwardRef(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (props: BusyOverlayProps, _ref: ForwardedRef<BusyOverlayHandle>) => {
            const [hidden, setHidden] = React.useState(props.initiallyHidden)
            React.useImperativeHandle(props.componentRef, () => ({
                setVisible: (b) => setHidden(!b),
                isVisible: () => hidden === true
            }))

            useEffect(() => {
                const cuiRoot = document.getElementById("cui")
                if (cuiRoot) {
                    if (hidden) {
                        LISTENER_TYPES.forEach((type) =>
                            cuiRoot.removeEventListener(type, preventInput)
                        )
                    } else {
                        LISTENER_TYPES.forEach((type) =>
                            cuiRoot.addEventListener(type, preventInput)
                        )
                        return () => {
                            LISTENER_TYPES.forEach((type) =>
                                cuiRoot.removeEventListener(type, preventInput)
                            )
                        }
                    }
                }
            }, [props])

            if (hidden) {
                return null
            }
            return <LoadingLayer />
        }
    )
