import Box from "@mui/system/Box"
import React from "react"
import { useParams } from "react-router-dom"

import { CUI } from "../../configuration/CUI"
import UserService from "../../services/sso/userService"

export default function Configuration() {
    const params = useParams()
    const { configurationId } = params

    if (!configurationId) {
        // no set found, do nothing
        return null
    }

    return (
        <Box>
            <Box component="main">
                <CUI
                    configurationId={configurationId}
                    token={UserService.getTokenString}
                />
            </Box>
        </Box>
    )
}
