import { faker } from "@faker-js/faker"

import {
    SetInterface,
    SetPricingInterface,
    SalesDocument
} from "../features/sets/sets.types"
import type {
    AppInterface,
    ConfigurationInterface,
    LineItemInterface,
    LineItemOptionInterface,
    PageInterface,
    TemplateSetInterface,
    UserInterface
} from "../utils/interfaces"

/**
 * A sample class representing a configuration
 */
class Configuration {
    id = ""
    status = ""
    qty = 0
    name = ""
    note = ""
    owner_id = 0
    modified: Date = new Date()
    modified_by_id = 0
    set_id = ""
    required_attribute = ""

    constructor(data: any) {
        for (const p in data) {
            switch (p) {
                case "id":
                case "name":
                case "note":
                case "status":
                case "set_id":
                case "required_attribute":
                    this[p] = String(data[p])
                    break
                case "qty":
                case "owner_id":
                case "modified_by_id":
                    this[p] = parseInt(data[p]) || 0
                    break
                case "modified":
                    this[p] = new Date(data[p])
                    break
            }
        }
    }

    get owner(): UserInterface | undefined {
        return MockupStore.users.find(
            (user: UserInterface) => user.id === this.owner_id
        )
    }

    get modified_by(): UserInterface | undefined {
        return MockupStore.users.find(
            (user: UserInterface) => user.id === this.modified_by_id
        )
    }

    get set(): SetInterface | undefined {
        return MockupStore.sets.find(
            (set: SetInterface) => set.id === this.set_id
        )
    }
}

/**
 * A sample class representing a set
 */
class Set {
    id = ""
    article_no = 0
    name = ""
    customer?: string
    owner_id = 0
    modified: Date = new Date()
    modified_by_id = 0
    status = "inactive"
    pricing: SetPricingInterface = new SetPricing({})

    constructor(data: any) {
        for (const p in data) {
            switch (p) {
                case "id":
                case "name":
                case "customer":
                case "status":
                    this[p] = String(data[p])
                    break
                case "article_no":
                case "owner_id":
                case "modified_by_id":
                    this[p] = parseInt(data[p]) || 0
                    break
                case "modified":
                    this[p] = new Date(data[p])
                    break
                case "pricing":
                    this[p] = new SetPricing(data[p])
                    break
            }
        }
    }

    get owner(): UserInterface | undefined {
        return MockupStore.users.find(
            (user: UserInterface) => user.id === this.owner_id
        )
    }

    get modified_by(): UserInterface | undefined {
        return MockupStore.users.find(
            (user: UserInterface) => user.id === this.modified_by_id
        )
    }

    get configurations(): ConfigurationInterface[] {
        return MockupStore.configurations.filter(
            (configuration: ConfigurationInterface) =>
                configuration.set_id === this.id
        )
    }
}

class SetPricing {
    productionCostConfigurator = 0
    productionCostCD = 0
    marginProductionSite = 0
    buyingPrice = 0
    margeEntrepreneur = 0
    suggestedCountryMarkup = 0
    transferPrice = 0
    minimumASP = 0
    suggestedASP = 0
    resultingTranferPrice = 0
    selectedCMIIMargin = 0
    individualASP = 0

    constructor(data: any) {
        for (const p in data) {
            switch (p) {
                case "productionCostConfigurator":
                case "productionCostCD":
                case "marginProductionSite":
                case "buyingPrice":
                case "margeEntrepreneur":
                case "suggestedCountryMarkup":
                case "transferPrice":
                case "minimumASP":
                case "suggestedASP":
                case "resultingTranferPrice":
                case "selectedCMIIMargin":
                case "individualASP":
                    this[p] = parseFloat((parseFloat(data[p]) || 0).toFixed(2))
                    break
            }
        }
    }
}

class Template {
    name = ""
    image?: string = ""
    subitems: string[] = []

    constructor(data: any) {
        for (const p in data) {
            switch (p) {
                case "name":
                case "image":
                    this[p] = String(data[p])
                    break
                case "subitems":
                    this[p] = Array.isArray(data[p])
                        ? data[p]
                        : data[p]
                        ? [data[p]]
                        : []
                    break
            }
        }
    }
}

const getRandomItemFromCollection = (items: any[]) => {
    if (items.length === 0) {
        return undefined
    } else if (items.length === 1) {
        return items[0]
    }

    const randomNumber = Math.round(Math.random() * (items.length - 1))

    return items[randomNumber]
}

/**
 * A class for fetching mockup data for the prototype
 */
class MockupData {
    numberOfSets = 10
    numberOfUsers = 3
    numberOfConfigurationsPerSet = 5

    public users: UserInterface[] = []
    public currentUserId = 0
    public sets: SetInterface[] = []
    public configurations: ConfigurationInterface[] = []
    public templatesLUER: TemplateSetInterface[] = []
    public templatesNRFit: TemplateSetInterface[] = []
    public mainPages: PageInterface[] = [
        {
            name: "Sets",
            route: "/sets"
        }
    ]

    public userPages: PageInterface[] = [
        {
            name: "Logout"
        }
    ]

    public app: AppInterface = {
        deployment: "PROD",
        name: "ProSet Configurator",
        version: "v1.0"
    }

    constructor() {
        // Set up arbitrary users
        for (let i = 0; i < this.numberOfUsers; i++) {
            this.users.push({
                id: i + 1,
                email: faker.internet.email(),
                name: faker.name.findName(),
                avatar: Math.random() > 0 ? faker.internet.avatar() : ""
            })
        }

        this.currentUserId = getRandomItemFromCollection(this.users).id

        // Set up arbitrary sets
        for (let i = 0; i < this.numberOfSets; i++) {
            const status: string[] = ["active", "inactive", "draft"]

            this.sets.push(
                new Set({
                    id: `BB${String(i + 200).padStart(6, "0")}`,
                    article_no: i + 4312,
                    name:
                        faker.lorem
                            .words(2)
                            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                letter.toUpperCase()
                            ) + " Set",
                    customer: faker.company.companyName(),
                    owner_id: getRandomItemFromCollection(this.users).id,
                    modified: faker.date.between(
                        "2020-01-01T00:00:00.000Z",
                        new Date().toISOString()
                    ),
                    modified_by_id: getRandomItemFromCollection(this.users).id,
                    status: status[
                        Math.round(Math.random() * (status.length - 1))
                    ],
                    pricing: {
                        productionCostConfigurator: (Math.random() + 0.5) * 6,
                        productionCostCD: (Math.random() + 0.5) * 6,
                        marginProductionSite: (Math.random() + 0.5) * 50,
                        buyingPrice: (Math.random() + 0.5) * 6,
                        margeEntrepreneur: (Math.random() + 0.5) * 1,
                        suggestedCountryMarkup: 100,
                        transferPrice: (Math.random() + 0.5) * 7,
                        minimumASP: (Math.random() + 0.5) * 8,
                        suggestedASP: (Math.random() + 0.5) * 12,
                        resultingTranferPrice: (Math.random() + 0.5) * 7,
                        selectedCMIIMargin: (Math.random() + 0.5) * 40,
                        individualASP: (Math.random() + 0.5) * 12
                    }
                })
            )
        }

        // Set up arbitrary configurations
        this.sets.forEach((set: SetInterface) => {
            for (let i = 0; i < this.numberOfConfigurationsPerSet; i++) {
                const status: string[] = ["complete", "incomplete"]

                this.configurations.push(
                    new Configuration({
                        id: `${set.id}-${String(i + 1).padStart(3, "0")}`,
                        status: status[
                            Math.round(Math.random() * (status.length - 1))
                        ],
                        qty: Math.round(Math.random() * 5),
                        name:
                            faker.lorem
                                .words(2)
                                .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                    letter.toUpperCase()
                                ) + " Configuration",
                        note: Math.round(Math.random())
                            ? faker.lorem.words(
                                  5 + Math.round(Math.random() * 10)
                              )
                            : "",
                        owner_id: getRandomItemFromCollection(this.users).id,
                        modified: faker.date.between(
                            set.modified.toISOString(),
                            new Date().toISOString()
                        ),
                        modified_by_id: getRandomItemFromCollection(this.users)
                            .id,
                        set_id: set.id,
                        required_attribute: "populated"
                    })
                )
            }
        })

        for (let i = 0; i < 3; i++) {
            this.templatesLUER.push(
                new Template({
                    name: faker.commerce.productName(),
                    image: faker.image.technics(200, 200) + "?lock=" + (i + 1),
                    subitems: [
                        faker.commerce.productName(),
                        faker.commerce.productName(),
                        faker.commerce.productName()
                    ]
                })
            )

            this.templatesNRFit.push(
                new Template({
                    name: faker.commerce.productName(),
                    image:
                        faker.image.technics(200, 200) + "?lock=" + (i + 1) * 2,
                    subitems: [
                        faker.commerce.productName(),
                        faker.commerce.productName(),
                        faker.commerce.productName()
                    ]
                })
            )
        }
    }

    get currentUser() {
        return this.getUserById(this.currentUserId)
    }

    getUserById(id = 0) {
        return this.users.find((user: UserInterface) => user.id === id)
    }

    getSetById(id = "") {
        return this.sets.find((set: SetInterface) => set.id === id)
    }

    createSet() {
        return JSON.parse(
            JSON.stringify(
                new Set({
                    id: "",
                    article_no: this.sets.length + 4312,
                    name: "New Set",
                    customer: "",
                    owner_id: this.currentUserId,
                    modified: new Date(),
                    modified_by_id: this.currentUserId,
                    status: "inactive",
                    configurations: []
                })
            )
        )
    }

    getConfigurationById(id = "") {
        return this.configurations.find(
            (configuration: ConfigurationInterface) => configuration.id === id
        )
    }

    createConfiguration(set: SalesDocument, name = "New Configuration") {
        return JSON.parse(
            JSON.stringify(
                new Configuration({
                    id: "",
                    name,
                    product: "",
                    owner_id: this.currentUserId,
                    modified: new Date(),
                    modified_by_id: this.currentUserId,
                    set_id: set.salesDocumentId,
                    required_attribute: ""
                })
            )
        )
    }

    createTemplate(num: number) {
        const items = []

        for (let i = 0; i < num; i++) {
            items.push({
                name: faker.commerce.productName(),
                image: faker.image.technics(200, 200, true),
                subitems: [
                    faker.commerce.productName(),
                    faker.commerce.productName(),
                    faker.commerce.productName()
                ]
            })
        }

        return items
    }

    getDummyCatalogue() {
        return [
            "Standard Sets",
            "Cannula",
            "Syringes",
            "Filters",
            "Patient drapes",
            "Swabs and compresses",
            "Gowns",
            "Ultrasound cover",
            "Clamps and tweezers",
            "Scalpels",
            "CVC accessory",
            "Sterile products (do not unpack / insert into unsterile chamber peel pouch)",
            "Bowls/Trays",
            "Miscellaneous"
        ].map((groupLabel, groupLabelId) => {
            return {
                id: groupLabelId,
                label: groupLabel,
                items: new Array(5 + Math.round(Math.random() * 20))
                    .join(",")
                    .split(",")
                    .map((s, ix) => {
                        return {
                            image: `https://loremflickr.com/200/200/schema?lock=${ix}`,
                            label: faker.commerce.productName(),
                            id:
                                faker.random.alpha(3).toUpperCase() +
                                faker.random.numeric(3) +
                                String(groupLabelId).padStart(2, "0") +
                                String(ix).padStart(4, "0"),
                            info:
                                Math.random() > 0.5
                                    ? faker.lorem.words(
                                          2 + Math.round(Math.random() * 10)
                                      )
                                    : undefined
                        }
                    })
            }
        })
    }

    getDummyLineItems() {
        return [
            { lock: false, title: "Perifix LOR 8 mi LS unsteril", qty: 1 },
            { lock: true, title: "Inject 1 ml LS unsteril", qty: 2 },
            { lock: false, title: "Omnifix 30 ml LL  steril", qty: 1 }
        ]
    }

    addOptionToLineItems = (
        lineItems: LineItemInterface[],
        option: LineItemOptionInterface,
        qty?: number
    ) => {
        let lineItem = lineItems.find((item) => item.id === option.id)
        if (qty === undefined) {
            qty = (lineItem ? lineItem.qty : 0) + 1
        }

        if (qty <= 0) {
            if (lineItem) {
                // remove lineItem
                const index = lineItems.indexOf(lineItem)
                lineItems.splice(index, 1)
            }

            return lineItems
        }

        if (!lineItem) {
            lineItem = Object.assign({ qty }, option)
            lineItems.push(lineItem)
        } else {
            lineItem.qty = qty
        }

        return lineItems
    }
}

// The final MockupStore for fetching mockup data, used by the classes
const MockupStore = new MockupData()

export default MockupStore
export { Set, Configuration, Template }
