import { getTheme } from "@fluentui/react"
import * as React from "react"

import { TextButton } from "@encoway/cui-configurator-components"
import { TextButtonProps } from "@encoway/cui-configurator-components/dist/esm/components/TextButton/TextButton.types"

import { borderRadius, customColors } from "../../themes/styles"

export const BBraunTextButton = (props: TextButtonProps) => {
    const theme = getTheme()
    return (
        <TextButton
            {...props}
            styles={{
                root: {
                    ".ms-Stack-inner, .ms-Stack-inner > *": {
                        margin: 0
                    },
                    ".ms-StackItem >  .ms-Button": {
                        border: "1px solid rgba(115, 115, 115, 0.5)",
                        borderRadius: 0,
                        padding: "0.1875rem 0.5625rem"
                    },
                    ".ms-StackItem:first-child > .ms-Button": {
                        borderBottomLeftRadius: borderRadius.borderRadius,
                        borderTopLeftRadius: borderRadius.borderRadius
                    },
                    ".ms-StackItem:last-child > .ms-Button": {
                        borderBottomRightRadius: borderRadius.borderRadius,
                        borderTopRightRadius: borderRadius.borderRadius
                    },
                    // Avoid overlap of option's borders
                    ".ms-StackItem:not(:first-child) > .ms-Button": {
                        borderLeftColor: "transparent"
                    },
                    // Hover-Style for not selected options
                    ".ms-StackItem > .ms-Button[class*='optionNotSelected']:hover, .ms-Button[class*='optionConflict']:hover":
                        {
                            backgroundColor:
                                theme.semanticColors.buttonBackgroundHovered,
                            border: `1px solid ${customColors.darkGray}`
                        }
                },
                optionSelected: {
                    boxShadow:
                        "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px"
                },
                optionTextNotSelected: {
                    color: customColors.darkGray
                }
            }}
        />
    )
}
