import AssignmentIcon from "@mui/icons-material/Assignment"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Popover from "@mui/material/Popover"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { DocumentTemplate } from "@encoway/sales-api-js-client/dist/masterData"

import { getSalesService } from "../../app/salesServiceHolder"
import { SalesDocument } from "../../features/sets/sets.types"
import { setPending } from "../../features/ui/uiSlice"
import { useUpdateSalesDocumentMutation } from "../../services/salesDocumentAPI"
import { GenerateDocumentsButtonStyles } from "./GenerateDocumentsButton.styles"

type Props = {
    set: SalesDocument | undefined
}

const documentTypes = [
    "quote.printOptions.format.pdf",
    "quote.printOptions.format.wordDocx"
]

export const GenerateDocumentsButton = ({ set }: Props) => {
    const [requestDocumentsPopoverAnchor, setRequestDocumentsPopoverAnchor] =
        React.useState<HTMLElement | null>(null)
    const [selectedDocumentTemplate, setSelectedDocumentTemplate] =
        useState<string>(
            set?.properties?.print_document_template
                ? set.properties.print_document_template
                : ""
        )

    const dispatch = useDispatch()
    const [updateSalesDocument] = useUpdateSalesDocumentMutation()

    const [selectedDocumentType, setSelectedDocumentType] = useState<string>(
        set?.properties?.print_document_type
            ? set.properties.print_document_type
            : documentTypes[0]
    )

    const [selectableTemplates, setSelectableTemplates] = useState<
        DocumentTemplate[]
    >([])

    const requestDocumentsPopoverIsOpen = Boolean(requestDocumentsPopoverAnchor)
    const requestDocumentsPopoverId = requestDocumentsPopoverIsOpen
        ? "simple-popover"
        : undefined

    useEffect(() => {
        const loadTemplates = async () => {
            setSelectableTemplates(
                await getSalesService().masterData.documentTemplates()
            )
        }

        loadTemplates().catch((e) =>
            console.error("Could not load document templates", e)
        )
    }, [])

    const requestDocuments = (anchorEl: HTMLElement) => {
        if (anchorEl) {
            setRequestDocumentsPopoverAnchor(anchorEl)
            anchorEl.classList.add("has--focus")
        }
    }

    const requestDocumentsByButton = (event: React.MouseEvent<HTMLElement>) => {
        const currentAnchorEl = event.currentTarget
        return requestDocuments(currentAnchorEl)
    }

    const closePopup = () => {
        const currentAnchorEl = requestDocumentsPopoverAnchor
        if (currentAnchorEl) {
            currentAnchorEl.classList.remove("has--focus")
        }

        setRequestDocumentsPopoverAnchor(null)
    }

    const updateDocumentTemplate = async (event: SelectChangeEvent) => {
        const newValue = event.target.value
        await updateSalesDocument({
            print_document_template: newValue
        })
        return setSelectedDocumentTemplate(newValue)
    }
    const updateDocumentType = async (event: SelectChangeEvent) => {
        const newValue = event.target.value
        await updateSalesDocument({
            print_document_type: newValue
        })
        setSelectedDocumentType(newValue)
    }
    const generateDocuments = async () => {
        if (set) {
            dispatch(setPending(true))
            const printResult = await getSalesService()
                .salesDocument.print()
                .catch((e) => {
                    console.error("Could not generate document templates", e)
                    return null
                })

            // TODO: Display some error message in a dialog if the printResult is NULL
            // We do that later
            if (printResult) {
                // use browser download function
                const a = document.createElement("a")

                a.target = "_blank"
                a.href = `data:${printResult.mimeType};base64,${printResult.data}`
                a.download = printResult.fileName

                a.click()
                a.remove()
            }
            dispatch(setPending(false))
        }
        closePopup()
    }

    return (
        <>
            <Button
                sx={GenerateDocumentsButtonStyles.trigger}
                variant="outlined"
                color="neutral"
                startIcon={<AssignmentIcon />}
                onClick={requestDocumentsByButton}
            >
                Documents
            </Button>

            <Popover
                id={requestDocumentsPopoverId}
                open={requestDocumentsPopoverIsOpen}
                onClose={closePopup}
                anchorEl={requestDocumentsPopoverAnchor}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
            >
                <Box sx={GenerateDocumentsButtonStyles.popup}>
                    <FormControl
                        sx={GenerateDocumentsButtonStyles.formControl}
                        size="small"
                        margin="normal"
                    >
                        <InputLabel id="document_type_label">
                            {t("application.document.template")}
                        </InputLabel>
                        <Select
                            labelId="document_type_label"
                            id="document_type"
                            value={selectedDocumentTemplate}
                            onChange={updateDocumentTemplate}
                            label={t("application.document.template")}
                        >
                            {selectableTemplates.map((template) => (
                                <MenuItem
                                    key={`selectable_template_${template.name}`}
                                    value={template.name}
                                >
                                    {template.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        sx={GenerateDocumentsButtonStyles.formControl}
                        size="small"
                        margin="normal"
                    >
                        <InputLabel id="document_format_label">
                            {t("application.document.type")}
                        </InputLabel>
                        <Select
                            labelId="document_format_label"
                            id="document_format"
                            value={selectedDocumentType}
                            onChange={updateDocumentType}
                            label={t("application.document.type")}
                        >
                            {documentTypes.map((type) => (
                                <MenuItem
                                    key={`document_type_${type}`}
                                    value={type}
                                >
                                    {t(type)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        sx={GenerateDocumentsButtonStyles.button}
                        variant="contained"
                        onClick={generateDocuments}
                    >
                        {t("application.document.generate")}
                    </Button>
                </Box>
            </Popover>
        </>
    )
}
