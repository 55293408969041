import { t } from "i18next"
import React from "react"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useCallbackPrompt } from "../../app/useCallbackPrompt"
import { Dialog, DialogButtonProps } from "../../components/Dialog"
import { useCloseCurrentSalesDocumentMutation } from "../../services/salesDocumentAPI"
import { closeKit, selectSaveActive } from "./setSlice"

export const ConfirmPageLeaveDialog = () => {
    const [closeSalesDocumentTrigger] = useCloseCurrentSalesDocumentMutation()
    const hasChanges = useAppSelector(selectSaveActive)
    const dispatch = useAppDispatch()

    // this blocks the navigation and shows a dialogue if there are any unsaved changes
    const { showPrompt, confirmNavigation, cancelNavigation } =
        useCallbackPrompt(hasChanges)

    const handleClose = () => cancelNavigation()

    const handleConfirmation = async () => {
        await closeSalesDocumentTrigger()
        dispatch(closeKit())
        confirmNavigation()
    }

    const dialogButtons: DialogButtonProps[] = [
        {
            text: `${t("application.common.yes")}`,
            onClick: handleConfirmation,
            color: "primary",
            variant: "contained"
        },
        {
            text: `${t("application.common.no")}`,
            onClick: handleClose,
            color: "neutral",
            variant: "outlined"
        }
    ]

    return (
        <Dialog
            open={showPrompt}
            onClose={handleClose}
            title={t("application.dialog.unsavedChangesTitle")}
            message={t("application.dialog.unsavedChanges")}
            buttons={dialogButtons}
        />
    )
}
