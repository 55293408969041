const GenerateDocumentsButtonStyles = {
    popup: {
        p: 1
    },
    formControl: {
        m: 1,
        minWidth: 120
    },
    button: {
        m: 1
    },
    trigger: {
        "&.is--disabled": {
            opacity: 0.5
        },
        display: "inline-flex"
    }
}

export { GenerateDocumentsButtonStyles }
