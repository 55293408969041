import { useDispatch } from "react-redux"

import { setPending } from "../features/ui/uiSlice"
import UserService from "../services/sso/userService"
import { getSalesService } from "./salesServiceHolder"

/**
 * Performs a logout
 */
export const useLogout = () => {
    const dispatch = useDispatch()
    const doLogout = async () => {
        dispatch(setPending(true))
        // Destroy server side session
        const salesService = getSalesService()
        await salesService.close()

        // Remove local access token
        UserService.logout()

        dispatch(setPending(false))

        // Redirect to main page
        /*
         * FIXME Switch to sales-showroom-auth component and logout additionally directly at authorization server
         * The current behaviour leads to that only the sales session is terminated, but not the SSO session.
         */
        window.location.href = window.location.origin
    }
    return [doLogout]
}
