import { ReactJSXElement } from "@emotion/react/types/jsx-namespace"
import { Stack } from "@fluentui/react/lib/Stack"
import { ITextProps, Text } from "@fluentui/react/lib/Text"
import * as React from "react"

import { ParameterTO, Value } from "@encoway/c-services-js-client"
import {
    ComponentName,
    ImageButtonOption,
    ImageButtonProps
} from "@encoway/cui-configurator-components"
import { ComponentFactory } from "@encoway/react-configurator"

import { customColors } from "../../themes/styles"

// any as type declaration to avoid problems with incompatible type declaration in cui
const InfoButton = (props: any) => {
    return ComponentFactory.instanceOf(ComponentName.InfoButton, props)
}

type ItemProps = Value & ParameterTO

export const BBraunImageButtonCaption = (
    props: ImageButtonProps,
    item?: ImageButtonOption,
    additionalButtons?: ReactJSXElement[]
) => {
    const imageButtonItem = item as unknown as ItemProps
    const textProps: ITextProps = {
        nowrap: false,
        style: {
            wordBreak: "break-word",
            textAlign: "left"
        }
    }

    return (
        <Stack
            horizontal={false}
            tokens={{ childrenGap: 0 }}
            verticalAlign="space-between"
            style={{ width: "100%", height: "100%" }}
        >
            <Stack.Item
                style={{
                    margin: "0.8rem 1rem"
                }}
            >
                <Text block className="captionTitle" {...textProps}>
                    {imageButtonItem.translatedValue}
                </Text>
                <Text block className="captionShortText" {...textProps}>
                    {imageButtonItem.shortText}
                </Text>
            </Stack.Item>
            <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                style={{
                    margin: "0 1rem",
                    padding: "0.8rem, 0",
                    borderTop: `1px solid ${customColors.lightGray}`
                }}
            >
                <Stack.Item>{additionalButtons ?? <></>}</Stack.Item>
                <Stack.Item className="infoButton-container">
                    <InfoButton {...props} data={item} />
                </Stack.Item>
            </Stack>
        </Stack>
    )
}
