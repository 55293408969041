const LoginStyles = {
    page: {
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: "450px",
        ml: "auto",
        mr: "auto"
    },
    form: {
        mt: 1,
        width: "100%"
    },
    submitBtn: {
        mt: 3,
        mb: 2
    }
}

export { LoginStyles }
