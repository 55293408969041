import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React from "react"

import { InputField } from "../../../../common/FormFields/InputField"
import { SalesDocument } from "../../../sets/sets.types"
import { useProperties } from "./generalData.hook"
import { generalDataStyles } from "./generalDataStyles"

type Props = {
    set: SalesDocument
}

export const PotentialData = ({ set }: Props) => {
    const { selectPropertyValue, setPropertyValue } = useProperties(set)
    return (
        <Box sx={generalDataStyles.section}>
            <Typography component="h3" variant="h5">
                Potential Data
            </Typography>
            <Grid
                container
                spacing={2}
                columns={{ xs: 2, md: 5 }}
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={1} md={1}>
                    <InputField
                        id="quantityPerYear"
                        label="Quantity p.a."
                        value={selectPropertyValue("quantityPerYear")}
                        onChange={(event) =>
                            setPropertyValue(
                                "quantityPerYear",
                                event.target.value
                            )
                        }
                    />
                </Grid>
                <Grid item xs={1} md={1}>
                    <InputField
                        id="competitorPrice"
                        label="Competitor price"
                        type="number"
                        value={selectPropertyValue("competitorPrice")}
                        onChange={(event) =>
                            setPropertyValue(
                                "competitorPrice",
                                event.target.value
                            )
                        }
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
