import AddIcon from "@mui/icons-material/Add"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import DeleteIcon from "@mui/icons-material/Delete"
import ImportExportIcon from "@mui/icons-material/ImportExport"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined"
import { Button } from "@mui/material"
import Stack from "@mui/material/Stack"
import {
    DataGrid,
    GridColumns,
    GridRenderEditCellParams,
    GridRowId,
    GridRowParams,
    GridValueFormatterParams,
    GridValueGetterParams
} from "@mui/x-data-grid"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Dialog, DialogButtonProps } from "../../components/Dialog"
import PopupNavigation from "../../components/PopupNavigation"
import StatusIcon from "../../components/StatusIcon"
import { TableListStyles } from "../../components/TableList.styles"
import TableQuickSearchToolBar from "../../components/TableQuickSearchToolBar"
import {
    salesDocumentAPI,
    useCreateSalesDocumentMutation,
    useDeleteSalesDocumentMutation,
    useDuplicateSalesDocumentMutation,
    useGetAllSalesDocumentsQuery,
    useOpenSalesDocumentMutation
} from "../../services/salesDocumentAPI"
import { newKit, setSalesDocumentPresent } from "../set/setSlice"
import { SalesDocument } from "./sets.types"
import {
    selectListOptions,
    setPage,
    setRowsPerPage,
    setSorting
} from "./setsSlice"

export const SetList = () => {
    const [duplicateSalesDocumentMutation] = useDuplicateSalesDocumentMutation()
    const [deleteSalesDocumentMutation] = useDeleteSalesDocumentMutation()
    const [openSalesDocumentMutation] = useOpenSalesDocumentMutation()
    const [newSalesDocumentMutation] = useCreateSalesDocumentMutation()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const listOptions = useAppSelector(selectListOptions)
    const { data: salesDocuments } = useGetAllSalesDocumentsQuery(listOptions)

    const sets = salesDocuments?.data ?? []
    const totalCount = salesDocuments?.totalCount ?? 0

    const { rowsPerPage } = useAppSelector(selectListOptions)
    const [openDialog, setOpenDialog] = useState(false)
    const [setToDelete, setSetToDelete] = useState<SalesDocument | null>(null)

    useEffect(() => {
        // invalidate the cached salesDocument. Otherwise, an "open" is not triggered again
        // and the server has still the old document
        dispatch(salesDocumentAPI.util.invalidateTags(["SalesDocument"]))
        dispatch(setSalesDocumentPresent(false))
    }, [])

    const deleteAction = React.useCallback(
        (id: GridRowId) => () => {
            const set = sets.find(
                (s: SalesDocument) => s.salesDocumentId === id
            )
            if (set) {
                setSetToDelete(set)
                setOpenDialog(true)
            }
        },
        [sets]
    )

    const editSet = async (id: string | number) => {
        const set = sets.find((s: SalesDocument) => s.salesDocumentId === id)
        if (set) {
            console.debug(
                `Edit Set "${set.salesDocumentId}" with name "${set.elementName}"`
            )
            const setId = set.properties.quote_id
            await openSalesDocumentMutation(setId)
            navigate(`/sets/${setId}`)
        }
    }

    const editAction = React.useCallback(
        (id: GridRowId) => () => {
            editSet(id)
        },
        // eslint-disable-next-line
        []
    )

    const duplicateAction = React.useCallback(
        (id: GridRowId) => () => {
            const set = sets.find(
                (s: SalesDocument) => s.salesDocumentId === id
            )
            if (set) {
                console.log(`Duplicate Set ${set.elementName}`)
                duplicateSalesDocumentMutation(set.salesDocumentId)
            }
        },
        [sets]
    )

    const newSetAction = React.useCallback(async () => {
        await newSalesDocumentMutation()
        dispatch(newKit())
    }, [])

    const onRowClick = (params: GridRowParams) => {
        editSet(params.id)
    }

    const getPropertyValue = (
        params: GridValueGetterParams<void, SalesDocument>
    ) => {
        return params.row.properties[params.field] || ""
    }
    const columns = React.useMemo<GridColumns<SalesDocument>>(
        () => [
            {
                field: "quote_id",
                headerName: `${t("application.common.drawingId")}`,
                disableColumnMenu: true,
                hideable: false,
                width: 120,
                valueGetter: getPropertyValue
            },
            {
                field: "article_no",
                disableColumnMenu: true,
                headerName: `${t("application.common.articleNo")}`,
                width: 200,
                valueGetter: getPropertyValue
            },
            {
                field: "name",
                disableColumnMenu: true,
                headerName: `${t("application.common.description")}`,
                flex: 1,
                minWidth: 200,
                valueGetter: getPropertyValue
            },
            {
                field: "quote_created_by",
                headerName: `${t("application.common.owner")}`,
                disableColumnMenu: true,
                minWidth: 200,
                flex: 1,
                valueGetter: getPropertyValue
            },
            {
                field: "quote_modified_at",
                headerName: `${t("application.common.modified")}`,
                disableColumnMenu: true,
                type: "date",
                width: 120,
                valueGetter: getPropertyValue,
                valueFormatter: (params: GridValueFormatterParams<number>) => {
                    if (params.value == null) {
                        return ""
                    }

                    const date = new Date(params.value)
                    return new Intl.DateTimeFormat(undefined, {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric"
                    }).format(date)
                }
            },
            {
                field: "quote_modified_by",
                headerName: `${t("application.common.modifiedBy")}`,
                disableColumnMenu: true,
                minWidth: 200,
                flex: 1,
                valueGetter: getPropertyValue
            },
            {
                field: "quote_status",
                headerName: `${t("application.common.status")}`,
                disableColumnMenu: true,
                hide: true,
                width: 120,
                valueGetter: getPropertyValue,
                renderCell: (params: GridRenderEditCellParams<string>) => {
                    return <StatusIcon status={params.value} withLabel={true} />
                }
            },
            {
                field: "actions",
                headerName: "",
                hideable: false,
                hideSortIcons: true,
                sortable: false,
                filterable: false,
                width: 60,
                cellClassName: "is--actions",
                headerClassName: "is--actions",
                renderCell: (params: GridRenderEditCellParams<string>) => {
                    const popUpNavigationItems = [
                        {
                            icon: <ModeEditIcon fontSize="small" />,
                            onClick: editAction(params.id),
                            text: `${t("application.common.edit")}`
                        },
                        {
                            icon: <ContentCopyIcon fontSize="small" />,
                            onClick: duplicateAction(params.id),
                            text: `${t("application.common.duplicate")}`
                        },
                        {
                            icon: <DeleteIcon fontSize="small" />,
                            onClick: deleteAction(params.id),
                            text: `${t("application.common.delete")}`
                        }
                    ]
                    return (
                        <PopupNavigation
                            popUpNavigationItems={popUpNavigationItems}
                        />
                    )
                }
            }
        ],
        [deleteAction, editAction, duplicateAction]
    )

    const onRenderColumnUnsortedIcon = () => (
        <ImportExportIcon fontSize="small" />
    )

    const onRenderColumnMenuIcon = () => (
        <ViewColumnOutlinedIcon fontSize="small" />
    )

    const onRenderNoRowsOverlay = () => (
        <Stack height="100%" alignItems="center" justifyContent="center">
            No Sets found
        </Stack>
    )

    const onRenderNoResultsOverlay = () => (
        <Stack height="100%" alignItems="center" justifyContent="center">
            Set filter returns no results
        </Stack>
    )

    const onRenderDataGridToolbar = () => (
        <TableQuickSearchToolBar>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                component={NavLink}
                to="/sets/new"
                onClick={() => newSetAction()}
            >
                {t("application.buttons.addSet")}
            </Button>
        </TableQuickSearchToolBar>
    )

    const handleClose = () => setOpenDialog(false)
    const handleDelete = () => {
        if (setToDelete) {
            deleteSalesDocumentMutation(setToDelete.salesDocumentId)
            setSetToDelete(null)
        }
        setOpenDialog(false)
    }

    const dialogButtons: DialogButtonProps[] = [
        {
            text: `${t("application.common.delete")}`,
            onClick: handleDelete,
            color: "primary",
            variant: "outlined"
        },
        {
            text: `${t("application.common.cancel")}`,
            onClick: handleClose,
            color: "primary",
            variant: "outlined"
        }
    ]

    return (
        <>
            <DataGrid
                sx={TableListStyles.dataGrid}
                rows={sets}
                columns={columns}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                autoHeight={true}
                disableSelectionOnClick
                onRowClick={onRowClick}
                getRowId={(row) => row.salesDocumentId}
                components={{
                    ColumnUnsortedIcon: onRenderColumnUnsortedIcon,
                    ColumnMenuIcon: onRenderColumnMenuIcon,
                    NoRowsOverlay: onRenderNoRowsOverlay,
                    NoResultsOverlay: onRenderNoResultsOverlay,
                    Toolbar: onRenderDataGridToolbar
                }}
                componentsProps={{
                    panel: {
                        sx: {
                            "& .MuiDataGrid-panelHeader": {
                                display: "none"
                            },
                            "& .MuiDataGrid-panelFooter": {
                                display: "none"
                            }
                        }
                    },
                    pagination: {
                        SelectProps: {
                            variant: "outlined",
                            size: "small"
                        },
                        showFirstButton: true,
                        showLastButton: true
                    }
                }}
                paginationMode="server"
                rowCount={totalCount}
                onPageChange={(newPage) => dispatch(setPage(newPage))}
                pageSize={rowsPerPage}
                onPageSizeChange={(newPageSize) =>
                    dispatch(setRowsPerPage(newPageSize))
                }
                sortingMode="server"
                onSortModelChange={(model) => dispatch(setSorting(model))}
            />
            <Dialog
                open={openDialog}
                onClose={handleDelete}
                title={t("application.dialog.deleteSetTitle")}
                message={t("application.dialog.deleteSet")}
                buttons={dialogButtons}
            />
        </>
    )
}
