import { createTheme } from "@mui/material/styles"

/**
 * Text color: #737373
 * Light grey: #ececec
 * Mid grey:   #D6D6D6
 * Dark grey:  #b0b0b0
 * Primary:    #00a97a
 * Secondary:  #9e2ab5
 */

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: "#00a97a",
            light: "#d8ffee",
            dark: "#008762"
        },
        secondary: {
            main: "#9e2ab5"
        },
        neutral: {
            main: "#737373",
            light: "#ececec",
            dark: "#737373"
        },
        text: {
            primary: "#636363",
            secondary: "#202020",
            disabled: "#D3D3D3"
        },
        error: {
            light: "#f0b6b9",
            main: "#e40147",
            dark: "#9c243f"
        },
        warning: {
            light: "#f7b95a",
            main: "#f29000",
            dark: "#cb6c13"
        },
        success: {
            main: "#00a97a",
            light: "#d8ffee",
            dark: "#008762"
        },
        info: {
            light: "#6eceff",
            main: "#2da0d3",
            dark: "#278eb4"
        }
    },
    shape: {
        borderRadius: 8
    },
    typography: {
        fontFamily: ['"Arial"', "Helvetica"].join(",")
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
        html,
        body,
        #root {
          height: 100%;
        }
      `
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: "bold",
                    textTransform: "none",
                    textAlign: "center",
                    whiteSpace: "nowrap"
                },
                outlined: {
                    "&:hover": {
                        backgroundColor: "#ececec"
                    }
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    minHeight: 212
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderWidth: 1,
                        borderColor: "#00a97a"
                    }
                },
                input: {
                    caretColor: "#00a97a"
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                outlined: {
                    "&.Mui-focused": {
                        color: "#00a97a"
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontWeight: "bold",
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    ".MuiSwitch-switchBase.Mui-checked.Mui-disabled": {
                        color: "#b0b0b0"
                    },
                    ".MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                        backgroundColor: "#D3D3D3"
                    },
                    ".MuiSwitch-switchBase.Mui-checked": {
                        color: "#737373"
                    }
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontWeight: "bold"
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginTop: 24,
                    ".MuiInputLabel-outlined.MuiInputLabel-shrink": {
                        top: -10,
                        left: -14
                    },
                    ".MuiInputLabel-outlined.Mui-focused": {
                        color: "inherit"
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                        marginTop: 5
                    },
                    ".MuiOutlinedInput-notchedOutline legend": {
                        display: "none"
                    },
                    ".MuiOutlinedInput-root.Mui-disabled": {
                        background: "#f9f9f9",
                        ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent"
                        }
                    }
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    ".MuiOutlinedInput-root": {
                        background: "#ececec",
                        borderRadius: 8
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    backgroundColor: "#ececec",
                    '&:not([aria-expanded="true"]) ~ .MuiOutlinedInput-notchedOutline':
                        {
                            borderColor: "transparent"
                        }
                }
            }
        }
    }
})

declare module "@mui/material/styles" {
    interface Palette {
        neutral: Palette["secondary"]
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        neutral?: PaletteOptions["secondary"]
    }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        neutral: true
    }
}

export default theme
