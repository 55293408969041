import { getTheme } from "@fluentui/react"
import * as React from "react"

import {
    InputField,
    InputFieldProps
} from "@encoway/cui-configurator-components"

import { borderRadius, customColors } from "../../themes/styles"

export const BBraunInputField = (props: InputFieldProps) => {
    const theme = getTheme()
    return (
        <InputField
            {...props}
            styles={{
                textField: {
                    ".ms-TextField-fieldGroup": {
                        ...borderRadius,
                        backgroundColor: customColors.lightGray,
                        border: 0,
                        minHeight: "2.5rem"
                    },
                    ".ms-TextField-fieldGroup:hover ": {
                        border: `1px solid ${customColors.darkGray}`
                    },
                    ".ms-TextField-fieldGroup:after": {
                        ...borderRadius,
                        border: `1px solid ${customColors.darkGreen}`,
                        caretColor: customColors.darkGreen
                    },
                    ".ms-TextField-fieldGroup > input": {
                        color: customColors.mediumGray,
                        fontSize: "1rem",
                        lineHeight: "1.5rem"
                    }
                },
                notReady: {
                    ".ms-TextField-fieldGroup": {
                        backgroundColor: customColors.lightGray,
                        border: `1px solid ${theme.semanticColors.warningIcon}`
                    }
                },
                error: {
                    ".ms-TextField-fieldGroup": {
                        backgroundColor: customColors.lightGray,
                        border: `1px solid ${theme.semanticColors.warningIcon}`
                    },
                    ".ms-TextField-errorMessage > span": {
                        color: theme.semanticColors.warningIcon
                    }
                }
            }}
        />
    )
}
