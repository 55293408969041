const StatusIconStyles = {
    wrapper: {
        "&.is--complete, &.is--active": {
            color: "success.main"
        },
        "&.is--incomplete, &.is--inactive": {
            color: "warning.main"
        },
        "&.is--draft": {
            color: "grey.400"
        },
        "> svg": {
            verticalAlign: "middle",
            mr: 0.5
        }
    }
}

export { StatusIconStyles }
