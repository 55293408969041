export const customColors = {
    lightGray: "#ECECEC",
    mediumGray: "#636363",
    darkGray: "#737373",
    lightGreen: "#D8FFEE",
    darkGreen: "#00A97A",
    almostBlack: "#202020",
    productGroups: {
        selected: "#00a97a14",
        selectedHovered: "#00a97a1f",
        hovered: "#0000000a"
    }
}

export const borderRadius = {
    borderRadius: "8px"
}
