import { Alert, Box } from "@mui/material"
import { Parser } from "html-to-react"
import React, { useState, useEffect } from "react"

import {
    Characteristics,
    ProductsFilter,
    Selection
} from "@encoway/c-services-js-client"

import { getCatalogService } from "../../../app/catalogServiceHolder"
import { useGetLineItemLeafsQuery } from "../../../services/lineItemAPI"
import { WarningStyles } from "./Warning.styles"

const PRODUCT_WARNING_CHARACTERISTIC = "PRODUCT_WARNING"

type productWithWarning = {
    id: string
    name: string
    warning: string
}

const Warning = () => {
    const { data } = useGetLineItemLeafsQuery()
    const items = data ?? []
    const lineItemProductIds = items.map((item) => item.properties.name)
    const parser = new Parser(React)

    const [productsWithWarning, setProductsWithWarning] = useState<
        productWithWarning[]
    >([])

    const catalogService = getCatalogService()

    useEffect(() => {
        if (lineItemProductIds.length === 0) {
            return
        }

        const fetchProducts = async () => {
            const productsResult = await catalogService.products(
                new Selection()
                    .characteristics(
                        new Characteristics().id(PRODUCT_WARNING_CHARACTERISTIC)
                    )
                    .filter(new ProductsFilter().id(lineItemProductIds))
            )

            const productsWithWarning = productsResult.products
                .filter(
                    (product) =>
                        product.characteristicValues[
                            PRODUCT_WARNING_CHARACTERISTIC
                        ]
                )
                .map((product) => ({
                    id: product.id,
                    name: product.name ? product.name : "",
                    warning:
                        product.characteristicValues[
                            PRODUCT_WARNING_CHARACTERISTIC
                        ]?.values.join()
                }))

            setProductsWithWarning(productsWithWarning)
        }

        fetchProducts()
    }, [items])

    if (lineItemProductIds.length === 0) {
        return <></>
    }

    return (
        <Box component="section">
            {productsWithWarning.map((product) => (
                <Alert
                    key={product.id}
                    sx={WarningStyles.message}
                    severity="warning"
                >
                    {parser.parse(
                        `<b>${product.id} - ${product.name}:</b> ${product.warning}`
                    )}
                </Alert>
            ))}
        </Box>
    )
}

export default Warning
