import Box from "@mui/material/Box"
import { t } from "i18next"
import React from "react"
import { useParams } from "react-router-dom"

import { useGetCurrentSalesDocument } from "../../app/salesService.hooks"
import Pricing from "../../components/Set/Detail/Pricing"
import Warning from "../../components/Set/Detail/Warning"
import Tabs from "../../components/Tabs"
import { SetInterface } from "../sets/sets.types"
import { GeneralData } from "./detail/generalData/GeneralData"
import { ItemList } from "./detail/itemList/ItemList"

export const SetTabs = () => {
    const { setId } = useParams()
    const set = useGetCurrentSalesDocument()

    // type to "SetInterface" because ConfigurationList and Pricing still work with this type
    // this will change later
    const wrongSet = set as unknown as SetInterface

    if (!set) {
        return null
    }

    return (
        <Box component="section">
            <Tabs
                selectedTabIndex={set && setId ? 1 : 0}
                tabs={[
                    {
                        title: `${t("application.common.generalData")}`,
                        component: <GeneralData set={set} />
                    },
                    {
                        title: `${t("application.common.itemList")}`,
                        component: <ItemList />
                    },
                    {
                        title: `${t("application.common.pricing")}`,
                        component: <Pricing set={wrongSet} />
                    },
                    {
                        title: `${t("application.common.warning")}`,
                        component: <Warning />
                    }
                ]}
            />
        </Box>
    )
}
