import {
    FontIcon,
    mergeStyleSets,
    registerIcons,
    Stack,
    StackItem
} from "@fluentui/react"
import { DeleteIcon, LockIcon, UnlockIcon } from "@fluentui/react-icons-mdl2"
import React, { ReactElement } from "react"

const LOCK_ICON = "LockIcon"
const UNLOCK_ICON = "UnlockIcon"
const DELETE_ICON = "DeleteIcon"
registerIcons(
    {
        icons: {
            [LOCK_ICON]: <LockIcon />,
            [UNLOCK_ICON]: <UnlockIcon />,
            [DELETE_ICON]: <DeleteIcon />
        }
    },
    { disableWarnings: true }
)

export type BillOfMaterialsRowStyles = {
    iconCell?: object
    lineItemCell?: object
    quantityCell?: object
    actionCell?: object
}

export type BillOfMaterialsRowProps = {
    lineItem: ReactElement | string
    quantity: ReactElement | string
    icon?: "LOCK" | "UNLOCK"
    action?: ReactElement
    styles?: BillOfMaterialsRowStyles
}

export const BillOfMaterialsRow = ({
    lineItem,
    quantity,
    icon,
    action,
    styles
}: BillOfMaterialsRowProps) => {
    const renderIconCell = () => {
        if (!icon) {
            return <></>
        }
        switch (icon) {
            case "LOCK":
                return <FontIcon aria-label="LockIcon" iconName={LOCK_ICON} />
            case "UNLOCK":
                return (
                    <FontIcon aria-label="UnlockIcon" iconName={UNLOCK_ICON} />
                )
        }
    }
    const renderActionCell = () => action ?? <></>

    const classNames = mergeStyleSets(
        {
            iconCell: { width: "1.5rem" },
            lineItemCell: {
                flexGrow: 1
            },
            quantityCell: {
                width: "4rem"
            },
            actionCell: { width: "1.5rem" }
        },
        styles
    )

    return (
        <StackItem>
            <Stack
                horizontal={true}
                style={{ padding: "0.25rem 0.75rem 0.25rem 1rem" }}
            >
                <StackItem className={classNames.iconCell}>
                    {renderIconCell()}
                </StackItem>
                <StackItem className={classNames.lineItemCell}>
                    {lineItem}
                </StackItem>
                <StackItem className={classNames.quantityCell}>
                    {quantity}
                </StackItem>
                <StackItem className={classNames.actionCell}>
                    {renderActionCell()}
                </StackItem>
            </Stack>
        </StackItem>
    )
}
