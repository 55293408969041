import { getTheme } from "@fluentui/react"
import * as React from "react"

import {
    InfoButton,
    InfoButtonProps
} from "@encoway/cui-configurator-components"

import { customColors } from "../../themes/styles"

export const BBraunInfoButton = (props: InfoButtonProps) => {
    const theme = getTheme()
    return (
        <InfoButton
            {...props}
            styles={{
                iconButton: {
                    color: customColors.mediumGray,
                    ":hover": {
                        color: theme.palette.black
                    }
                }
            }}
        />
    )
}
