import { ButtonProps } from "@mui/material"
import Button from "@mui/material/Button"
import React from "react"

export const HighlightedButton = (props: ButtonProps) => {
    return (
        <Button {...props} variant="contained" color="secondary">
            {props.children}
        </Button>
    )
}
