import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"

import { LineItem } from "@encoway/sales-api-js-client"

import { getSalesService } from "../app/salesServiceHolder"

type FolderArgs = {
    name: string
    parentId?: string
}

type ProductArgs = {
    productId: string
    quantity: number
    parentId?: string
}

export const lineItemAPI = createApi({
    reducerPath: "lineItemAPI",
    baseQuery: fetchBaseQuery(), // we don't need the base query right now, that's why it's empty
    endpoints: (builder) => ({
        getLineItems: builder.query<LineItem[], string | null>({
            async queryFn(lineItemId) {
                const lineItems = lineItemId
                    ? await getSalesService().lineItems.get(lineItemId)
                    : await getSalesService().lineItems.get()
                return { data: lineItems }
            },
            keepUnusedDataFor: 0
        }),
        getLineItemLeafs: builder.query<LineItem[], void>({
            async queryFn() {
                const leafs: LineItem[] = []

                // Unable to call an endpoint from another. At least a could not find how...
                // If there is a workaround, feel free to change this.
                const getLineItems = async (lineItemId: string | null) => {
                    return lineItemId
                        ? await getSalesService().lineItems.get(lineItemId)
                        : await getSalesService().lineItems.get()
                }

                const findLeafs = async (lineItemId: string | null) => {
                    const lineItems = await getLineItems(lineItemId)
                    for (const lineItem of lineItems) {
                        if (lineItem.properties.isLeaf) {
                            leafs.push(lineItem)
                        } else {
                            // repeat above for all children of every non leaf line item
                            await findLeafs(lineItem.lineItemId).catch(
                                (reason) => console.log("failed", reason)
                            )
                        }
                    }
                }
                await findLeafs(null)
                return { data: leafs }
            },
            keepUnusedDataFor: 0
        }),
        addFolder: builder.mutation<LineItem | null, FolderArgs>({
            async queryFn(folderArgs) {
                const addedFolder = await getSalesService().lineItems.addFolder(
                    folderArgs.name,
                    folderArgs.parentId
                )
                if (Object.keys(addedFolder.addedChildLineItems).length === 1) {
                    const lineItems = await getSalesService().lineItems.get()
                    const filtered = lineItems.filter(
                        (lineItem) =>
                            lineItem.properties.label === folderArgs.name
                    )
                    return { data: filtered[0] }
                }
                return { data: null }
            }
        }),
        addProduct: builder.mutation<boolean, ProductArgs>({
            async queryFn(productArgs) {
                // Add product to line item
                const added = await getSalesService().lineItems.addProduct(
                    productArgs.productId
                )
                // If failed to add product to line item, return false
                if (Object.keys(added.failedLineItemIds).length === 1) {
                    return { data: false }
                }
                // Get line item id from added product
                const productLineItemId = Object.values(
                    added.addedChildLineItems
                )[0][0].lineItemId

                // Update the quantity from added product
                const updated = await getSalesService().lineItems.update(
                    productLineItemId,
                    { newAmount: productArgs.quantity }
                )
                // If failed to update product, return false
                // But what is with added product?
                // Do we should delete the product from line items?
                if (Object.keys(updated.failedLineItemIds).length === 1) {
                    return { data: false }
                }

                // If there is a parentId given, move product to this parent.
                // This is a workaround, because there is a bug when using parentLineItemId while adding a product
                if (productArgs.parentId) {
                    const moved = await getSalesService().lineItems.move(
                        productLineItemId,
                        productArgs.parentId
                    )
                    if (Object.keys(moved.failedLineItemIds).length === 1) {
                        return { data: false }
                    }
                }
                return { data: true }
            }
        }),
        remove: builder.mutation<boolean, string[]>({
            async queryFn(lineItemIds) {
                const removed = await getSalesService().lineItems.delete(
                    lineItemIds
                )
                if (Object.keys(removed.failedLineItemIds).length === 0) {
                    return { data: true }
                }
                return { data: false }
            }
        })
    })
})

export const {
    useGetLineItemsQuery,
    useLazyGetLineItemsQuery,
    useGetLineItemLeafsQuery,
    useAddFolderMutation,
    useAddProductMutation,
    useRemoveMutation
} = lineItemAPI
