const TabsStyles = {
    tabpanel: {
        paddingTop: 4
    },
    tabWrapper: {
        borderBottom: 1,
        borderColor: "divider"
    },
    tab: {
        color: "text.primary",
        "&.Mui-selected": {
            backgroundColor: "transparent"
        },
        "&:hover": {
            backgroundColor: "neutral.light"
        },
        "&.Mui-selected:hover": {
            backgroundColor: "primary.light"
        }
    },
    tabFooter: {
        display: "flex",
        flexWrap: "wrap",
        mt: 5,

        ".MuiDivider-root": {
            flex: "0 0 100%",
            mb: 4
        }
    },
    tabNavBack: {
        marginLeft: 0,
        marginRight: "auto"
    },
    tabNavNext: {
        marginLeft: "auto",
        marginRight: 0
    }
}

export { TabsStyles }
