import { t } from "i18next"
import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { assertDefined } from "../../app/assertions"
import { useOpenConfiguration } from "../../app/configuration.hooks"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useGetCurrentSalesDocument } from "../../app/salesService.hooks"
import { GenerateDocumentsButton } from "../../components/Set/GenerateDocumentsButton"
import { NewConfigurationDialog } from "../../features/configuration/NewConfigurationDialog"
import {
    clearChanges,
    selectChanges,
    selectSaveActive
} from "../../features/set/setSlice"
import { useOpenConfigurationMutation } from "../../services/configurationAPI"
import { useLazyGetLineItemsQuery } from "../../services/lineItemAPI"
import { useSaveSalesDocumentMutation } from "../../services/salesDocumentAPI"
import { HighlightedButton } from "../Buttons/HighlightedButton"
import { NeutralButton } from "../Buttons/NeutralButton"

export const SetActions = () => {
    const { setId } = useParams()
    assertDefined(setId, "setId is not defined")
    const salesDocument = useGetCurrentSalesDocument()
    const changes = useAppSelector(selectChanges)
    const saveActive = useAppSelector(selectSaveActive)
    const dispatch = useAppDispatch()
    const [saveSalesDocument] = useSaveSalesDocumentMutation()
    const navigate = useNavigate()
    const [showConfigurationDialog, setShowConfigurationDialog] =
        useState(false)
    const [loadLineItems] = useLazyGetLineItemsQuery()

    const [openConfiguration] = useOpenConfigurationMutation()
    const [configurationUrl, setConfigurationUrl] = useState<string>("")

    useOpenConfiguration(configurationUrl)

    if (!salesDocument) {
        return <></>
    }

    const onSaveSalesDocument = async () => {
        const result = await saveSalesDocument(changes)
        if ("data" in result) {
            await dispatch(clearChanges())
            if (setId === "new") {
                navigate(`/sets/${result.data.properties.quote_id}`)
            }
        } else {
            // TODO: Error Handling
        }
    }

    return (
        <>
            {showConfigurationDialog && (
                <NewConfigurationDialog
                    configurationId={"new"}
                    onClose={() => setShowConfigurationDialog(false)}
                />
            )}
            <NeutralButton
                onClick={async () => {
                    setConfigurationUrl("")
                    const { data: lineItemsTest } = await loadLineItems(null)
                    const configurableArticle = lineItemsTest?.find(
                        (lineItem) => lineItem.properties.configurable
                    )
                    if (configurableArticle) {
                        const result = await openConfiguration(
                            configurableArticle.lineItemId
                        )
                        if ("data" in result) {
                            setConfigurationUrl(result.data)
                        }
                    } else {
                        setShowConfigurationDialog(true)
                    }
                }}
            >
                {t("application.common.configuration")}
            </NeutralButton>
            <GenerateDocumentsButton set={salesDocument} />
            <HighlightedButton
                variant="contained"
                color="secondary"
                disabled={!saveActive}
                onClick={onSaveSalesDocument}
            >
                {t("application.common.save")}
            </HighlightedButton>
        </>
    )
}
