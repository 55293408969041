import { Http, CatalogService } from "@encoway/c-services-js-client"

let catalogService: CatalogService | null = null

export const initCatalogService = async (http: Http) => {
    if (catalogService) {
        throw new Error("catalogService already initialized")
    }
    catalogService = new CatalogService(http, "", "de-DE")
    return catalogService
}

export const getCatalogService = () => {
    if (!catalogService) {
        throw new Error("catalogService not initialized")
    }
    return catalogService
}
