import { DefaultButton } from "@fluentui/react/lib/Button"
import { Dialog, DialogFooter } from "@fluentui/react/lib/Dialog"
import { Stack } from "@fluentui/react/lib/Stack"
import React, { useState } from "react"

import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import {
    selectComponentsAndAccessoriesFilters,
    setComponentsAndAccessoriesFilters
} from "../../../../../features/set/setSlice"
import { useGetProductGroupFilterCharacteristicsQuery } from "../../../../../services/catalogAPI"
import { ProductsFilterCharacteristic } from "./ProductsFilterCharacteristic"
import { FilterCharacteristic, FilterSelection } from "./filter.types"

export type ProductsFilterCalloutProps = {
    productGroupId: string
    hidden: boolean
    onDismiss: () => void
}

export const ProductsFilterDialog = ({
    productGroupId,
    hidden,
    onDismiss
}: ProductsFilterCalloutProps) => {
    const filters = useAppSelector(selectComponentsAndAccessoriesFilters)
    const { data: filterCharacteristics } =
        useGetProductGroupFilterCharacteristicsQuery(productGroupId)
    const dispatch = useAppDispatch()
    const [selectedFilters, setSelectedFilters] =
        useState<FilterSelection>(filters)

    const applyFilters = () => {
        dispatch(setComponentsAndAccessoriesFilters(selectedFilters))
        onDismiss()
    }

    const clearFilters = () => {
        setSelectedFilters({})
        dispatch(setComponentsAndAccessoriesFilters({}))
        onDismiss()
    }

    const onFilterChange = (characteristicId: string, value: any) =>
        setSelectedFilters({ ...selectedFilters, [characteristicId]: value })

    return (
        <Dialog
            hidden={hidden}
            onDismiss={onDismiss}
            minWidth={650}
            styles={{
                main: {
                    borderRadius: "8px"
                }
            }}
            modalProps={{ topOffsetFixed: true }}
        >
            <Stack>
                {filterCharacteristics?.map(
                    (filterCharacteristic: FilterCharacteristic) => (
                        <Stack.Item
                            key={`filter-${filterCharacteristic.characteristicId}`}
                        >
                            <ProductsFilterCharacteristic
                                selectedFilters={selectedFilters}
                                onFilterChange={onFilterChange}
                                filterCharacteristic={filterCharacteristic}
                            />
                        </Stack.Item>
                    )
                )}
            </Stack>
            <DialogFooter>
                <DefaultButton
                    text={"Clear"}
                    onClick={clearFilters}
                    style={{ border: "0" }}
                />
                <DefaultButton
                    text={"Filter"}
                    onClick={applyFilters}
                    style={{ marginRight: "0.5em", borderRadius: "8px" }}
                />
            </DialogFooter>
        </Dialog>
    )
}
