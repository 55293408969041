import { t } from "i18next"

import { useAppDispatch, useAppSelector } from "../app/hooks"
import { closeKit, selectSaveActive } from "../features/set/setSlice"
import { SalesDocument } from "../features/sets/sets.types"
import { useCloseCurrentSalesDocumentMutation } from "../services/salesDocumentAPI"
import type { PageInterface } from "./interfaces"

type BreadcrumbParams = {
    set?: SalesDocument
    configuration?: string
}

const useGetBreadcrumbPagesByRoute = (
    path: string,
    params: BreadcrumbParams
) => {
    const [closeSalesDocumentTrigger] = useCloseCurrentSalesDocumentMutation()
    const dispatch = useAppDispatch()
    const hasChanges = useAppSelector(selectSaveActive)

    const route = path
        .split("/")
        .map((seg: string) => seg.trim().toLocaleLowerCase())
        .filter(Boolean)
        .join("/")

    const pages: PageInterface[] = []

    if (!route) {
        return pages
    }

    const { set, configuration } = params

    const closeSalesDocumentClickHandler = async () => {
        if (!hasChanges) {
            // Do only close the SalesDoc directly if no changes are present
            await closeSalesDocumentTrigger()
            dispatch(closeKit())
        }
    }

    if (/^sets\/?/.test(route)) {
        pages.push({
            name: t("application.common.kits"),
            route: "/sets",
            onClick: closeSalesDocumentClickHandler
        })

        if (set) {
            const setId = set.properties.quote_id
            pages.push({
                name: set.properties.name,
                route: `/sets/${setId}`,
                status: set.properties.quote_status,
                appendix: set.properties.quote_id
            })

            if (configuration) {
                pages.push({
                    name: `${t("application.common.configuration")}`,
                    route: `/sets/${setId}/${configuration}`
                })
            }
        }
    }

    return pages
}

export default useGetBreadcrumbPagesByRoute
