export const translations = {
    de: {
        "Configuration.TabsFooter.Previous": "Zurück",
        "Configuration.TabsFooter.Next": "Weiter"
    },
    en: {
        "Configuration.TabsFooter.Previous": "Previous",
        "Configuration.TabsFooter.Next": "Next"
    }
}
