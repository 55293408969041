import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined"
import { IconButton, ListItemIcon, MenuItem, Typography } from "@mui/material"
import Menu from "@mui/material/Menu"
import React, { ReactNode, useState } from "react"

type Item = {
    icon: ReactNode
    onClick: () => void
    text: string
}

interface ComponentInterface {
    id?: string
    popUpNavigationItems?: Item[]
}

export default function Component({
    id,
    popUpNavigationItems
}: ComponentInterface) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
        event.stopPropagation()
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const _id = id || "popup-navigation"

    return (
        <div>
            <IconButton
                id={`${_id}-button`}
                aria-controls={open ? `${_id}-menu` : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <MoreVertOutlinedIcon />
            </IconButton>
            <Menu
                id={`${_id}-menu`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": `${_id}-button`
                }}
            >
                {popUpNavigationItems &&
                    popUpNavigationItems.map((popUpNavigationItem, index) => {
                        const handleMenuItemClick = () => {
                            handleClose()
                            popUpNavigationItem.onClick()
                        }
                        const key = `popUpNavigationItem-${index}`

                        return (
                            <MenuItem key={key} onClick={handleMenuItemClick}>
                                <ListItemIcon>
                                    {popUpNavigationItem.icon}
                                </ListItemIcon>
                                <Typography variant="body2">
                                    {popUpNavigationItem.text}
                                </Typography>
                            </MenuItem>
                        )
                    })}
            </Menu>
        </div>
    )
}
