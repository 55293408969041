import {
    Constants as RestApiConstants,
    ParameterTO
} from "@encoway/c-services-js-client"
import { onValueChanged } from "@encoway/react-configurator"

import { useAppDispatch } from "../../../../app/hooks"
import { setComponentsAndAccessories } from "../../../../features/set/setSlice"

export const useCatalogCompositionBomHelper = (
    onValueChangedHandler: onValueChanged | null
) => {
    const dispatch = useAppDispatch()
    const parameterPropertyKey =
        "BomConfigurationConstants.ComponentsAndAccessories"

    const getCurrentOptionalValues = (
        parameterProperties: Record<string, unknown>
    ) => {
        const values: string | undefined =
            parameterProperties &&
            (parameterProperties[parameterPropertyKey] as string)
        return (values ? JSON.parse(values) : {}) as Record<string, number>
    }

    const getBom = (parameter: ParameterTO) => {
        return getCurrentOptionalValues(
            parameter.properties as Record<string, unknown>
        )
    }

    const getBomProducts = (parameter: ParameterTO) => getBom(parameter)

    const addProductToBom = (
        parameter: ParameterTO,
        productId: string,
        quantity: number
    ) => {
        const current = getBom(parameter)
        let newQuantity = quantity
        if (current[productId]) {
            newQuantity = current[productId] + quantity
        }
        changeProductInBom(parameter, productId, newQuantity)
    }

    const changeProductInBom = (
        parameter: ParameterTO,
        productId: string,
        quantity: number
    ) => {
        const current = getBom(parameter)
        current[productId] = quantity

        saveToParameter(parameter, current)
    }

    const deleteProductInBom = (parameter: ParameterTO, productId: string) => {
        const current = getBom(parameter)
        delete current[productId]

        saveToParameter(parameter, current)
    }

    const saveToParameter = (
        parameter: ParameterTO,
        current: Record<string, number>
    ) => {
        if (!onValueChangedHandler) {
            console.error("There is no onValueChanged method!")
            return false
        }
        dispatch(setComponentsAndAccessories(current))
        const paramProps = {
            [parameterPropertyKey]: JSON.stringify(current)
        }
        onValueChangedHandler(
            parameter,
            { parameterProperties: paramProps },
            RestApiConstants.ValueFormat.Unformatted
        )
    }

    return {
        getBomProducts,
        addProductToBom,
        changeProductInBom,
        deleteProductInBom
    }
}
