import { loadTheme } from "@fluentui/react"

export const customTheme = () => {
    return loadTheme({
        defaultFontStyle: {
            fontFamily: "Arial, Helvetica",
            fontSize: "0.875rem",
            lineHeight: "1.3125rem"
        },
        palette: {
            themePrimary: "#737373",
            themeLighterAlt: "#050505",
            themeLighter: "#121212",
            themeLight: "#222222",
            themeTertiary: "#454545",
            themeSecondary: "#656565",
            themeDarkAlt: "#818181",
            themeDark: "#949494",
            themeDarker: "#b0b0b0",
            neutralLighterAlt: "#ffffff",
            neutralLighter: "#ffffff",
            neutralLight: "#ffffff",
            neutralQuaternaryAlt: "#ffffff",
            neutralQuaternary: "#ffffff",
            neutralTertiaryAlt: "#ffffff",
            neutralTertiary: "#d5d5d5",
            neutralSecondary: "#ababab",
            neutralPrimaryAlt: "#848484",
            neutralPrimary: "#737373",
            neutralDark: "#575757",
            black: "#404040",
            white: "#ffffff"
        },
        semanticColors: {
            warningBackground: "#ECECEC",
            warningIcon: "#C5004B"
        }
    })
}
