import * as React from "react"

import {
    ImageButton,
    ImageButtonOption,
    ImageButtonProps
} from "@encoway/cui-configurator-components"

import { borderRadius, customColors } from "../../themes/styles"
import { BBraunImageButtonCaption } from "./BBraunImageButtonCaption"

export const BBraunImageButton = (props: ImageButtonProps) => {
    const onRenderCaption = (item?: ImageButtonOption) => {
        return BBraunImageButtonCaption(props, item)
    }

    return (
        <ImageButton
            onRenderCaption={onRenderCaption}
            {...props}
            styles={{
                root: {
                    ".ms-Stack-inner > button": {
                        padding: 0,
                        minWidth: "21.5rem",
                        maxWidth: "21.5rem"
                    },
                    ".ms-Stack-inner > .catalogCompositionProductParentContainer":
                        {
                            padding: 0,
                            minWidth: "13.75rem",
                            cursor: "auto"
                        },
                    ".ms-Image > img": {
                        minWidth: "100%",
                        minHeight: "100%"
                    },
                    ".ms-Stack-inner > button > .ms-Stack, .ms-Stack-inner > .catalogCompositionProductParentContainer":
                        {
                            ...borderRadius,
                            boxShadow:
                                "rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px",
                            overflow: "hidden"
                        },
                    // tabindex = 0 to access selected ImageButton
                    "button[tabindex='0'] > .ms-Stack": {
                        boxShadow:
                            "rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px"
                    },
                    ".captionTitle": {
                        color: customColors.mediumGray,
                        fontSize: "1.25rem",
                        fontWeight: 700
                    },
                    ".captionShortText": {
                        color: customColors.almostBlack,
                        fontSize: "1rem",
                        lineHeight: "1.5rem",
                        margin: "1.5rem 0 1rem 0"
                    },
                    ".infoButton-container > div": {
                        float: "right",
                        padding: "10px 0"
                    }
                },
                imageContainer: {
                    border: 0
                },
                imageContainerSelected: {
                    border: 0
                },
                upperCaption: {
                    border: 0,
                    width: "100%",
                    margin: 0
                }
            }}
        />
    )
}
