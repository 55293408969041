import Box from "@mui/material/Box"
import React from "react"

import { ConfirmPageLeaveDialog } from "./ConfirmPageLeaveDialog"
import { SetTabs } from "./SetTabs"

export const Set = () => {
    return (
        <Box>
            <Box component="main">
                <SetTabs />
            </Box>
            <ConfirmPageLeaveDialog />
        </Box>
    )
}
