import { Box } from "@mui/material"
import { GridToolbarQuickFilter } from "@mui/x-data-grid"
import React, { ReactNode } from "react"

import { TableQuickSearchToolbarStyles } from "./TableQuickSearchToolbar.styles"

interface ComponentInterface {
    children?: ReactNode
}

export default function Component({ children }: ComponentInterface) {
    return (
        <Box sx={TableQuickSearchToolbarStyles.tableTop}>
            <GridToolbarQuickFilter
                variant="outlined"
                sx={TableQuickSearchToolbarStyles.search}
                size="small"
            />
            <Box sx={TableQuickSearchToolbarStyles.tableTopOptions}>
                {children}
            </Box>
        </Box>
    )
}
