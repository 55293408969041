import { initializeIcons } from "@fluentui/react"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import React from "react"
import { RouterProvider } from "react-router-dom"

import { useInitApplication } from "./app/initApplication.hooks"
import { router } from "./app/router"
import { SSO } from "./services/sso/authenticationClient.types"
import theme from "./theme"

declare global {
    interface Window {
        sso: SSO
    }
}

initializeIcons()

export const App = () => {
    const { initialized, initializationError } = useInitApplication()

    // TODO: this should be part of a general error handling or something
    if (initializationError) {
        return <div>Could not initialize Sales service</div>
    }

    if (!initialized) {
        return null
    }

    return (
        <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <RouterProvider router={router} />
        </ThemeProvider>
    )
}
