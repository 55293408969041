import { Backdrop, BoxProps, Stack, StackProps, styled } from "@mui/material"
import React from "react"

import loadingIndicator from "./Loading_Indicator.png"

type BackdropIntegration = {
    type?: "overlay" | "integrated"
}

type Props = BackdropIntegration & {
    show?: boolean
}

const StyledBackdrop = styled(Backdrop)<BoxProps & BackdropIntegration>(
    ({ theme, type }) => ({
        color: "#FFFFFF",
        zIndex: theme.zIndex.modal + 1,
        position: type === "integrated" ? "absolute" : "fixed"
    })
)

const StyledStack = styled(Stack)<StackProps>((theme) => ({
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    boxShadow:
        "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;",
    color: theme.theme.palette.text.secondary,
    height: "11.125em",
    width: "20.25em"
}))

const StyledInnerStack = styled(Stack)<StackProps>({
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxHeight: "6em",
    overflow: "hidden",
    marginTop: "1em"
})

export const LoadingLayer = ({ show = true, type = "overlay" }: Props) => {
    return (
        <StyledBackdrop open={show} type={type}>
            <StyledStack>
                <StyledInnerStack>
                    <img
                        src={loadingIndicator}
                        alt="Loading Indicator"
                        width="350"
                    />
                </StyledInnerStack>
                <p>Loading...</p>
            </StyledStack>
        </StyledBackdrop>
    )
}
