import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"

import {
    ConfigurationStatus,
    UpdateServerResponse
} from "@encoway/sales-api-js-client"

import { getSalesService } from "../app/salesServiceHolder"

const configurationStatus = (): Promise<ConfigurationStatus> =>
    getSalesService().configuration.status()
const stopConfigurationIfRunning = async () => {
    const status = await configurationStatus()
    if (status.running) {
        return getSalesService().configuration.stop()
    }
    return Promise.resolve(null)
}

const createAdditionalConfigurationData = (
    accessories: Record<string, number>
) => ({
    addAccessoriesToBOM: {
        accessories
    }
})

const performHTTPCall = async (url: string, payloadAsString: string) => {
    const salesService = getSalesService()
    const http = salesService.http
    return http.json(`${url};jsessionid=${salesService.sessionId}`, {
        method: "POST",
        headers: new Headers({
            "encoway-client-session": salesService.clientSessionId,
            "Content-Type": "application/json"
        }),
        body: payloadAsString
    })
}

export const configurationAPI = createApi({
    reducerPath: "configurationAPI",
    baseQuery: fetchBaseQuery(), // we don't need the base query right now, that's why it's empty
    endpoints: (builder) => ({
        createConfiguration: builder.mutation<string, string>({
            async queryFn(productId) {
                await stopConfigurationIfRunning()
                const configurationUrl =
                    await getSalesService().configuration.create(productId)
                return { data: configurationUrl }
            }
        }),
        openConfiguration: builder.mutation<string, string>({
            async queryFn(lineItemId) {
                await stopConfigurationIfRunning()
                const configurationUrl =
                    await getSalesService().configuration.open(lineItemId)
                return { data: configurationUrl }
            }
        }),
        addConfiguration: builder.query<
            UpdateServerResponse,
            Record<string, number>
        >({
            async queryFn(accessories) {
                const payload = {
                    additionalConfigurationData: JSON.stringify(
                        createAdditionalConfigurationData(accessories)
                    )
                }

                // Perform fetch call via http, because the salesService does not support
                // the createAdditionalConfigurationData as payload in 24.9
                const result = await performHTTPCall(
                    "api/sales/salesdocument/configuration/add",
                    JSON.stringify(payload)
                )
                return { data: result }
            }
        }),
        saveConfiguration: builder.query<
            UpdateServerResponse,
            Record<string, number>
        >({
            async queryFn(accessories) {
                // Perform fetch call via http, because the salesService does not support
                // the createAdditionalConfigurationData as payload in 24.9
                const payload = JSON.stringify(
                    JSON.stringify(
                        createAdditionalConfigurationData(accessories)
                    )
                )
                const result = await performHTTPCall(
                    "api/sales/salesdocument/configuration/save",
                    payload
                )
                return { data: result }
            }
        }),
        stopConfiguration: builder.mutation<void, void>({
            async queryFn() {
                await stopConfigurationIfRunning()
                return { data: undefined as void }
            }
        })
    })
})

export const {
    useCreateConfigurationMutation,
    useOpenConfigurationMutation,
    useLazySaveConfigurationQuery,
    useLazyAddConfigurationQuery,
    useStopConfigurationMutation
} = configurationAPI
