import CloseIcon from "@mui/icons-material/Close"
import { IconButton } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Box from "@mui/system/Box"
import React, { useState } from "react"

import { Product } from "@encoway/sales-api-js-client"

import { useOpenConfiguration } from "../../app/configuration.hooks"
import { useGetConfigurableProducts } from "../../app/salesService.hooks"
import { useCreateConfigurationMutation } from "../../services/configurationAPI"
import { useUpdateSalesDocumentMutation } from "../../services/salesDocumentAPI"
import { ConnectorType } from "../sets/sets.types"
import { ConfigurableProduct } from "./ConfigurableProduct"
import { ConnectorTypeSwitch } from "./ConnectorTypeSwitch"
import { newConfigurationDialogStyles } from "./newConfigurationDialog.styles"

type Props = {
    configurationId?: string
    onClose: () => void
}

export const NewConfigurationDialog = ({ configurationId, onClose }: Props) => {
    const [referenceSetIsValid, setReferenceSetIsValid] = useState(
        configurationId !== ""
    )
    const [connectorType, setConnectorType] = useState<ConnectorType>("LUER")
    const [configurationUrl, setConfigurationUrl] = useState<string>("")
    const [updateSalesDocumentMutation] = useUpdateSalesDocumentMutation()
    const [createNewConfiguration] = useCreateConfigurationMutation()
    const configurableProducts = useGetConfigurableProducts()
    useOpenConfiguration(configurationUrl)

    if (!configurationId) {
        return <></>
    }

    const selectReferenceSet = (value: string) => {
        console.log(
            `Select Reference Set “${value}” for this new configuration.`
        )

        setReferenceSetIsValid(value !== "")
    }

    const selectConfigurableProduct = async (productId: string) => {
        console.log(
            `Select Area Set ”${productId}“ for this new configuration.`
        )
        setConfigurationUrl("")
        // set connectorType to sales doc. this way, it will be set as sales context property to restrict the configuration
        await updateSalesDocumentMutation({ connectorType })
        const result = await createNewConfiguration(productId)
        if ("data" in result) {
            setConfigurationUrl(result.data)
        }
    }

    const closeDialog = () => {
        console.log("Closing NewConfiguration dialog.")
        selectReferenceSet("")
        setConfigurationUrl("")
        onClose()
    }

    return (
        <Dialog
            open={true}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            fullWidth={true}
        >
            <DialogTitle variant="body1" id="alert-dialog-title">
                Configure Epidural Perifix
                <IconButton
                    aria-label="close"
                    onClick={closeDialog}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={newConfigurationDialogStyles.section}>
                    <Typography>Configure By Reference Set No</Typography>

                    <Box
                        sx={newConfigurationDialogStyles.selectReferenceWrapper}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="template_id"
                            label="Reference set"
                            name="template_id"
                            autoComplete="template_id"
                            variant="outlined"
                            autoFocus
                            size="small"
                            onChange={(event) =>
                                selectReferenceSet(event.currentTarget.value)
                            }
                        />

                        <Button
                            disabled={!referenceSetIsValid}
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                createNewConfiguration("Epidural_Perifix")
                            }}
                        >
                            Create
                        </Button>
                    </Box>
                </Box>

                <Box sx={newConfigurationDialogStyles.section}>
                    <ConnectorTypeSwitch
                        connectorType={connectorType}
                        setConnectorType={setConnectorType}
                    />
                    <Grid
                        container
                        spacing={2}
                        columns={{ xs: 1, md: 2 }}
                        justifyContent="flex-start"
                        alignItems="stretch"
                        sx={newConfigurationDialogStyles.templatesWrapper}
                    >
                        {configurableProducts?.map((product: Product) => (
                            <ConfigurableProduct
                                key={product.productId}
                                product={product}
                                onSelect={() =>
                                    selectConfigurableProduct(product.productId)
                                }
                            />
                        ))}
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
