import { ActionButton } from "@fluentui/react/lib/Button"
import { Stack } from "@fluentui/react/lib/Stack"
import React, { useState } from "react"

import {
    ImageButtonOption,
    ImageButtonProps
} from "@encoway/cui-configurator-components"
import { Constraints } from "@encoway/sales-api-js-client"

import { useAppSelector } from "../../../../app/hooks"
import { selectComponentsAndAccessoriesFilters } from "../../../../features/set/setSlice"
import { useGetProductsQuery } from "../../../../services/catalogAPI"
import { BBraunImageButton } from "../BBraunImageButton"
import { BBraunImageButtonCaption } from "../BBraunImageButtonCaption"
import { useCatalogCompositionBomHelper } from "./catalogComposition.hooks"
import { ProductsFilter } from "./filter/ProductsFilter"
import { FilterSelection } from "./filter/filter.types"

type Props = {
    productGroupId: string
} & ImageButtonProps

const filtersToConstraints = (filters: FilterSelection): Constraints[] => {
    return (
        Object.entries(filters)
            .map((entry) => {
                const [characteristicId, value] = entry
                const operator = "="
                return {
                    characteristicId,
                    operator,
                    value
                }
            })
            .filter((entry) => entry.value) ?? []
    )
}

export const Products = (props: Props) => {
    const [allProducts] = useState(props.data.values ?? [])
    const [isListView, setListView] = useState(false)
    const filters = useAppSelector(selectComponentsAndAccessoriesFilters)
    const { data: foundProducts } = useGetProductsQuery({
        productGroupId: props.productGroupId,
        constraints: filtersToConstraints(filters)
    })
    const { addProductToBom } = useCatalogCompositionBomHelper(
        props.onValueChanged
    )

    if (!allProducts || !foundProducts) {
        return <></>
    }
    const productIds: string[] =
        foundProducts?.map((product) => product.productId) ?? []
    const filteredProducts = allProducts.filter((value) =>
        productIds.includes(value.value)
    )

    const onRenderCell = (item: any, defaultRender: any) => {
        const defaultCell = defaultRender(item)

        if (!defaultCell) {
            return <></>
        }

        const imageSize = isListView ? 150 : 220

        return (
            <Stack
                key={`product-item-${item.value}`}
                className={`catalogCompositionProductParentContainer ${defaultCell.props.className}`}
                styles={{
                    root: {
                        ...defaultCell.props.style,
                        flexDirection: isListView ? "row" : "column",
                        width: isListView
                            ? "100%"
                            : defaultCell.props.style.width,
                        ".ms-Image": {
                            width: `${imageSize}px !important`,
                            height: `${imageSize}px !important`
                        },
                        ".captionShortText": {
                            margin: "1rem 0 0 0"
                        }
                    }
                }}
            >
                {defaultCell.props.children.props.children}
            </Stack>
        )
    }

    const addProduct = (item?: ImageButtonOption) =>
        item && addProductToBom(props.data, item.value, 1)

    const onRenderCaption = (item?: ImageButtonOption) => {
        return BBraunImageButtonCaption(
            {
                ...props,
                styles: { root: { height: "100px !important" } }
            },
            item,
            [
                <ActionButton
                    key="0"
                    name="productAddButton"
                    iconProps={{ iconName: "Add" }}
                    onClick={() => addProduct(item)}
                >
                    Add
                </ActionButton>
            ]
        )
    }

    return (
        <>
            <Stack horizontal horizontalAlign="space-between">
                <ProductsFilter productGroupId={props.productGroupId} />
                <ActionButton
                    iconProps={{
                        iconName: isListView ? "waffle" : "GlobalNavButton"
                    }}
                    onClick={() => setListView(!isListView)}
                ></ActionButton>
            </Stack>
            <BBraunImageButton
                {...props}
                data={{ ...props.data, values: filteredProducts }}
                onRenderCell={onRenderCell}
                onRenderCaption={onRenderCaption}
            />
        </>
    )
}
