import { useTheme } from "@mui/material"
import TextField from "@mui/material/TextField"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import {
    DatePicker as MuiDatePicker,
    DatePickerProps
} from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import * as React from "react"

type Props = {
    label: string
}

export const DatePicker = ({
    label,
    ...datePickerProps
}: Props & DatePickerProps<any, any>) => {
    const theme = useTheme()

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            {/* Localize Moment: https://mui.com/x/react-date-pickers/adapters-locale/#LocalizationMoment.tsx */}
            <MuiDatePicker
                inputFormat="DD/MM/YYYY"
                label={label}
                InputProps={{
                    sx: {
                        "& > .MuiInputBase-input ": {
                            paddingTop: "0.53rem",
                            paddingBottom: "0.53rem"
                        },
                        "& > .MuiInputAdornment-root > .MuiButtonBase-root:active .MuiSvgIcon-root":
                            { color: "green" }
                    }
                }}
                PaperProps={{
                    sx: {
                        "& .MuiPickersDay-today": {
                            borderColor: theme.palette.secondary.main
                        }
                    }
                }}
                PopperProps={{
                    placement: "bottom-start"
                }}
                {...datePickerProps}
                renderInput={(params?) => (
                    <TextField
                        {...params}
                        error={false}
                        InputLabelProps={{ shrink: true }}
                        helperText={null}
                    />
                )}
            />
        </LocalizationProvider>
    )
}
