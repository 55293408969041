import React from "react"
import { createHashRouter, Navigate, Outlet } from "react-router-dom"

import { Layout } from "../Layout"
import Configuration from "../features/configuration/Configuration"
import { Set } from "../features/set/Set"
import { Sets } from "../features/sets/Sets"
import Login from "../routes/Login"
import ResetPassword from "../routes/ResetPassword"
import Signup from "../routes/Signup"

export const router = createHashRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            { path: "login", element: <Login /> },
            { path: "reset-password", element: <ResetPassword /> },
            { path: "signup", element: <Signup /> },
            { path: "/", element: <Navigate to="/sets" /> },
            {
                path: "sets",
                element: <Outlet />,
                children: [
                    { index: true, element: <Sets /> },
                    {
                        path: ":setId",
                        element: <Set />,
                        handle: {
                            actionBarActions: "set"
                        }
                    },
                    {
                        path: ":setId/:configurationId",
                        element: <Configuration />,
                        handle: {
                            actionBarActions: "configuration"
                        }
                    }
                ]
            }
        ]
    }
])
