import {
    Alert,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    TextField
} from "@mui/material"
import Typography from "@mui/material/Typography"
import { Box } from "@mui/system"
import React from "react"

import { SetInterface } from "../../../features/sets/sets.types"
import { PricingStyles } from "./Pricing.styles"

interface ComponentInterface {
    set?: SetInterface
}

export default function Component({ set }: ComponentInterface) {
    return (
        <Box component="section">
            <Alert sx={PricingStyles.message} severity="error">
                There is no product hierarchy selected
            </Alert>

            <Box sx={PricingStyles.section}>
                <Typography component="h3" variant="h5">
                    Production
                </Typography>

                <Grid
                    container
                    spacing={2}
                    columns={{ xs: 1, md: 2 }}
                    justifyContent="flex-start"
                    alignItems="stretch"
                >
                    <Grid item>
                        <TextField
                            fullWidth
                            size="small"
                            value={set?.pricing.productionCostConfigurator}
                            margin="normal"
                            label="Production cost (accoring to configurator)"
                            sx={PricingStyles.textField}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        €
                                    </InputAdornment>
                                )
                            }}
                            disabled={true}
                        />

                        <TextField
                            fullWidth
                            size="small"
                            value={set?.pricing.productionCostCD}
                            margin="normal"
                            label="Production cost (determined by C&D)"
                            helperText="Overwritten by: ()"
                            sx={PricingStyles.textField}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        €
                                    </InputAdornment>
                                )
                            }}
                        />

                        <TextField
                            fullWidth
                            size="small"
                            value={set?.pricing.marginProductionSite}
                            margin="normal"
                            label="Margin production site"
                            sx={PricingStyles.textField}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                )
                            }}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item>
                        <List dense={true}>
                            <ListItem>
                                <Typography variant="body2">
                                    <strong>
                                        Global GP II in % regarding to
                                    </strong>
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="recommended sales price:"
                                    secondary="50.00"
                                ></ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="chosen sales price:"
                                    secondary="50.00"
                                ></ListItemText>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={PricingStyles.section}>
                <Typography component="h3" variant="h5">
                    ProSet (Entrepreneur Melsungen)
                </Typography>

                <Grid
                    container
                    spacing={2}
                    columns={{ xs: 1, md: 2 }}
                    justifyContent="flex-start"
                    alignItems="stretch"
                >
                    <Grid item>
                        <TextField
                            fullWidth
                            size="small"
                            value={set?.pricing.buyingPrice}
                            margin="normal"
                            label="Buying price"
                            sx={PricingStyles.textField}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        €
                                    </InputAdornment>
                                )
                            }}
                            disabled={true}
                        />

                        <TextField
                            fullWidth
                            size="small"
                            value={set?.pricing.margeEntrepreneur}
                            margin="normal"
                            label="Marge Entrepreneur"
                            sx={PricingStyles.textField}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                )
                            }}
                            disabled={true}
                        />

                        <TextField
                            fullWidth
                            size="small"
                            value={set?.pricing.suggestedCountryMarkup}
                            margin="normal"
                            label="Suggested markup for country / product hierarchy"
                            sx={PricingStyles.textField}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                )
                            }}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item>
                        <List dense={true}>
                            <ListItem>
                                <Typography variant="body2">
                                    <strong>
                                        GP II ex ProSet in % regarding to
                                    </strong>
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="recommended sales price:"
                                    secondary="40.43"
                                ></ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="chosen sales price:"
                                    secondary="40.43"
                                ></ListItemText>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={PricingStyles.section}>
                <Typography component="h3" variant="h5">
                    Country: Germany
                </Typography>

                <Grid
                    container
                    spacing={2}
                    columns={{ xs: 1, md: 2 }}
                    justifyContent="flex-start"
                    alignItems="stretch"
                >
                    <Grid item>
                        <TextField
                            fullWidth
                            size="small"
                            label="Transfer price to country organization"
                            value={set?.pricing.transferPrice}
                            margin="normal"
                            sx={PricingStyles.textField}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        €
                                    </InputAdornment>
                                )
                            }}
                            disabled={true}
                        />

                        <TextField
                            fullWidth
                            size="small"
                            label="Minimum ASP"
                            value={set?.pricing.minimumASP}
                            margin="normal"
                            sx={PricingStyles.textField}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        €
                                    </InputAdornment>
                                )
                            }}
                            disabled={true}
                        />

                        <TextField
                            fullWidth
                            size="small"
                            label="Suggested ASP"
                            value={set?.pricing.suggestedASP}
                            margin="normal"
                            sx={PricingStyles.textField}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        €
                                    </InputAdornment>
                                )
                            }}
                            disabled={true}
                        />

                        <Divider sx={PricingStyles.divider} />

                        <TextField
                            fullWidth
                            size="small"
                            label="Resulting transfer price to country organization"
                            value={set?.pricing.resultingTranferPrice}
                            margin="normal"
                            sx={PricingStyles.textField}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        €
                                    </InputAdornment>
                                )
                            }}
                            disabled={true}
                        />

                        <TextField
                            fullWidth
                            size="small"
                            value={set?.pricing.selectedCMIIMargin}
                            margin="normal"
                            label="Selected CMII Margin in country/product hierarchy"
                            helperText="Filled in by: ()"
                            sx={PricingStyles.textField}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                )
                            }}
                        />

                        <TextField
                            fullWidth
                            size="small"
                            value={set?.pricing.individualASP}
                            margin="normal"
                            label="Individual ASP to customer"
                            helperText="Filled in by: ()"
                            sx={PricingStyles.textField}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <List dense={true}>
                            <ListItem>
                                <Typography variant="body2">
                                    <strong>
                                        GP II country in % regarding to
                                    </strong>
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="recommended sales price:"
                                    secondary="40.00"
                                ></ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="chosen sales price:"
                                    secondary="40.00"
                                ></ListItemText>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
