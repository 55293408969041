import Button from "@mui/material/Button"
import Link from "@mui/material/Link"
import MenuItem from "@mui/material/MenuItem"
import { SxProps } from "@mui/system"
import React from "react"
import type { LinkProps } from "react-router-dom"
import { NavLink, useMatch, useResolvedPath } from "react-router-dom"

interface ComponentInterface extends LinkProps {
    sx?: SxProps
    type?: string
}

export default function Component({
    children,
    to,
    sx,
    type,
    ...props
}: ComponentInterface) {
    const resolved = useResolvedPath(to)
    const match = useMatch({ path: resolved.pathname, end: true })

    if (type === "MenuItem") {
        return (
            <MenuItem
                component={NavLink}
                to={to}
                sx={sx}
                className={match ? "is--active" : ""}
            >
                {children}
            </MenuItem>
        )
    } else if (type === "Button") {
        return (
            <Button
                component={NavLink}
                to={to}
                sx={sx}
                className={match ? "is--active" : ""}
            >
                {children}
            </Button>
        )
    } else {
        return (
            <Link
                component={NavLink}
                to={to}
                {...props}
                className={match ? "is--active" : ""}
            >
                {children}
            </Link>
        )
    }
}
