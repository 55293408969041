const PricingStyles = {
    textField: {
        ".Mui-disabled .MuiInputAdornment-root .MuiTypography-root": {
            color: "rgba(0, 0, 0, 0.38)"
        }
    },
    message: {
        mb: 2
    },
    divider: {
        mt: 2,
        mb: 2
    },
    section: {
        mb: 6
    }
}

export { PricingStyles }
