import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"

import counterReducer from "../features/counter/counterSlice"
import setReducer from "../features/set/setSlice"
import setsReducer from "../features/sets/setsSlice"
import uiReducer from "../features/ui/uiSlice"
import { catalogAPI } from "../services/catalogAPI"
import { configurationAPI } from "../services/configurationAPI"
import { lineItemAPI } from "../services/lineItemAPI"
import { salesDocumentAPI } from "../services/salesDocumentAPI"
import { customMiddleware } from "./customMiddleware"

export const createStore = () =>
    configureStore({
        reducer: {
            counter: counterReducer,
            sets: setsReducer,
            set: setReducer,
            ui: uiReducer,
            // APIs
            [salesDocumentAPI.reducerPath]: salesDocumentAPI.reducer,
            [configurationAPI.reducerPath]: configurationAPI.reducer,
            [lineItemAPI.reducerPath]: lineItemAPI.reducer,
            [catalogAPI.reducerPath]: catalogAPI.reducer
        },
        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .concat(salesDocumentAPI.middleware)
                .concat(configurationAPI.middleware)
                .concat(lineItemAPI.middleware)
                .concat(catalogAPI.middleware)
                .concat(customMiddleware.handleRejectedRequests)
                .concat(customMiddleware.handlePendingRequests)
    })

export const store = createStore()

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
