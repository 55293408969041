import { useNavigate } from "react-router"

export const useOpenConfiguration = (configurationUrl?: string) => {
    const navigate = useNavigate()

    if (configurationUrl) {
        const url = new URL(
            `${location.protocol}//${location.host}/${configurationUrl}`
        )
        const extractedConfigurationId = url.searchParams.get("configurationId")
        if (extractedConfigurationId) {
            navigate(
                `${location.hash.substring(1)}/${extractedConfigurationId}`
            )
        }
    }
}
