import { IRenderFunction, Link } from "@fluentui/react"
import { Text } from "@fluentui/react/lib/Text"
import * as React from "react"

import { TabsFooter } from "@encoway/cui-configurator-components"
import {
    ButtonProps,
    TabsFooterProps
} from "@encoway/cui-configurator-components/dist/esm/components/TabsFooter/TabsFooter.types"
import { L10n } from "@encoway/l10n"

import { borderRadius, customColors } from "../../themes/styles"

export const BBraunTabsFooter = (props: TabsFooterProps) => {
    const renderPreviousButton: IRenderFunction<ButtonProps> = (
        bProps?: ButtonProps
    ) => {
        return (
            <Link
                onClick={bProps?.onClick}
                className={bProps?.classNames.footerLink}
            >
                <Text nowrap>
                    {L10n.format("Configuration.TabsFooter.Previous", {})}
                </Text>
            </Link>
        )
    }

    const renderNextButton: IRenderFunction<ButtonProps> = (
        bProps?: ButtonProps
    ) => {
        return (
            <Link
                onClick={bProps?.onClick}
                className={bProps?.classNames.footerLink}
            >
                <Text nowrap>
                    {L10n.format("Configuration.TabsFooter.Next", {})}
                </Text>
            </Link>
        )
    }

    return (
        <TabsFooter
            {...props}
            onRenderPreviousButton={renderPreviousButton}
            onRenderNextButton={renderNextButton}
            styles={{
                footer: {
                    borderTop: `1px solid ${customColors.lightGray}`,
                    "button > *": {
                        color: customColors.darkGray,
                        fontWeight: "bold"
                    },
                    button: {
                        ...borderRadius,
                        ":hover, :active": {
                            backgroundColor: customColors.lightGray,
                            color: customColors.darkGray,
                            border: `1px solid ${customColors.darkGray}`,
                            textDecoration: "none"
                        },
                        border: "1px solid rgba(115, 115, 115, 0.5)",
                        padding: "0.3rem 1rem",
                        marginTop: "2rem",
                        minHeight: "2.25rem"
                    }
                },
                footerLink: {
                    ":active:hover": {
                        textDecoration: "none"
                    }
                }
            }}
        />
    )
}
