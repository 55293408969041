import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "../../app/store"
import { FilterSelection } from "../../configuration/components/configurator/catalogComposition/filter/filter.types"
import { SalesDocument } from "../sets/sets.types"

export type SetChanges = {
    [key: string]: string
}

export type SetChange = {
    propertyId: string
    propertyValue: string
}

type SetState = {
    setId: string | null
    isNew: boolean
    changes: SetChanges
    configurationChanged: boolean
    componentsAndAccessories: Record<string, number>
    salesDocumentPresent: boolean
    componentsAndAccessoriesFilters: FilterSelection
}

const initialState: SetState = {
    setId: null,
    isNew: false,
    changes: {},
    configurationChanged: false,
    componentsAndAccessoriesFilters: {},
    componentsAndAccessories: {},
    salesDocumentPresent: false
}

const setSlice = createSlice({
    name: "set",
    initialState,
    reducers: {
        setSetId: (state, action: PayloadAction<string>) => {
            state.setId = action.payload
            state.changes = {}
        },
        setChanges: (state, action: PayloadAction<SetChanges>) => {
            Object.keys(action.payload).forEach(
                (property) =>
                    (state.changes[property] = action.payload[property])
            )
        },
        setChange: (state, action: PayloadAction<SetChange>) => {
            const { propertyId, propertyValue } = action.payload
            state.changes[propertyId] = propertyValue
        },
        removeChange: (state, action: PayloadAction<string>) => {
            if (state.changes[action.payload]) {
                delete state.changes[action.payload]
            }
        },
        clearChanges: (state) => {
            doClearChanges(state)
        },
        setConfigurationChanged: (state, action: PayloadAction<boolean>) => {
            state.configurationChanged = action.payload
        },
        setSalesDocumentPresent: (state, action: PayloadAction<boolean>) => {
            state.salesDocumentPresent = action.payload
        },
        setComponentsAndAccessories: (
            state,
            action: PayloadAction<Record<string, number>>
        ) => {
            state.componentsAndAccessories = action.payload
        },
        setComponentsAndAccessoriesFilters: (
            state,
            action: PayloadAction<FilterSelection>
        ) => {
            state.componentsAndAccessoriesFilters = action.payload
        },
        newKit: (state) => {
            doClearChanges(state)
            state.salesDocumentPresent = false
        },
        closeKit: (state) => {
            doClearChanges(state)
            state.salesDocumentPresent = false
        }
    }
})

const doClearChanges = (state: any) => {
    state.changes = {}
    state.configurationChanged = false
    state.componentsAndAccessories = {}
}

export const {
    setChanges,
    setChange,
    removeChange,
    clearChanges,
    setConfigurationChanged,
    setComponentsAndAccessories,
    setSalesDocumentPresent,
    setComponentsAndAccessoriesFilters,
    newKit,
    closeKit
} = setSlice.actions

export const selectChanges = (state: RootState): SetChanges => state.set.changes
export const selectPropertyValue =
    (propertyId: string, set: SalesDocument) => (state: RootState) =>
        state.set.changes[propertyId] ?? set.properties[propertyId] ?? ""

export const selectSaveActive = (state: RootState) =>
    Object.keys(state.set.changes).length > 0 || state.set.configurationChanged

export const selectComponentsAndAccessories = (state: RootState) =>
    state.set.componentsAndAccessories

export const selectComponentsAndAccessoriesFilters = (state: RootState) =>
    state.set.componentsAndAccessoriesFilters

export default setSlice.reducer
