import { ButtonProps } from "@mui/material"
import Button from "@mui/material/Button"
import React from "react"

export const NeutralButton = (props: ButtonProps) => {
    return (
        <Button {...props} variant="outlined" color="neutral">
            {props.children}
        </Button>
    )
}
