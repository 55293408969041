import Box from "@mui/system/Box"
import React, { useEffect } from "react"

import { useStopConfigurationMutation } from "../../services/configurationAPI"
import { SetList } from "./SetList"

export const Sets = () => {
    const [stopConfiguration] = useStopConfigurationMutation()

    useEffect(() => {
        stopConfiguration()
    }, [])

    return (
        <Box>
            <Box>
                <Box component="main">
                    <SetList />
                </Box>
            </Box>
        </Box>
    )
}
