import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"

import { App } from "./App"
import { store } from "./app/store"
import { CUI } from "./configuration/CUI"
import reportWebVitals from "./reportWebVitals"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

if (
    process.env.NODE_ENV !== "production" &&
    process.env.REACT_APP_RUN_CUI === "true"
) {
    // TODO: Clarify if we need this any longer
    root.render(<CUI token={() => "anyTokenString"} />)
} else {
    root.render(
        <Provider store={store}>
            <App />
        </Provider>
    )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
