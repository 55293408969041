import MockupStore from "../theme/mockup-data"
import type { PageInterface } from "./interfaces"

const getPageTitleByBreadcrumbItems = (pages: PageInterface[]) => {
    return [
        pages.map((page: PageInterface) => page.name)[0],
        MockupStore.app.name
    ]
        .filter(Boolean)
        .join(" - ")
}

export default getPageTitleByBreadcrumbItems
