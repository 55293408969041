import React from "react"
import { useNavigate, useParams } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { HighlightedButton } from "../../common/Buttons/HighlightedButton"
import {
    useLazyAddConfigurationQuery,
    useLazySaveConfigurationQuery
} from "../../services/configurationAPI"
import { useGetLineItemsQuery } from "../../services/lineItemAPI"
import { useGetCurrentSalesDocumentQuery } from "../../services/salesDocumentAPI"
import {
    selectComponentsAndAccessories,
    setConfigurationChanged
} from "../set/setSlice"

type Props = {
    isDirty: boolean
    isValid: boolean
}

export const ConfigurationActions = ({ isDirty, isValid }: Props) => {
    const [saveConfigurationQuery] = useLazySaveConfigurationQuery()
    const [addConfigurationQuery] = useLazyAddConfigurationQuery()
    const { data: lineItems } = useGetLineItemsQuery(null)
    const { setId } = useParams()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const bomProducts = useAppSelector(selectComponentsAndAccessories)

    const { refetch: refreshSalesDocument } = useGetCurrentSalesDocumentQuery()

    const postConfigurationAction = () => {
        dispatch(setConfigurationChanged(true))
        // force re-fetch of salesDoc, so we get the new properties for riskClass etc.
        refreshSalesDocument()
        navigate(`/sets/${setId}`)
    }

    return (
        <>
            <HighlightedButton
                disabled={!(isDirty && isValid)}
                onClick={() => {
                    if (lineItems?.length === 0) {
                        addConfigurationQuery(bomProducts).then(
                            postConfigurationAction
                        )
                    } else {
                        saveConfigurationQuery(bomProducts).then(
                            postConfigurationAction
                        )
                    }
                }}
            >
                Save
            </HighlightedButton>
        </>
    )
}
