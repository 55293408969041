import { useGetProductsQuery } from "../services/catalogAPI"
import { useGetCurrentSalesDocumentQuery } from "../services/salesDocumentAPI"

export const useGetCurrentSalesDocument = () => {
    return useGetCurrentSalesDocumentQuery().data
}

export const useGetConfigurableProducts = () => {
    const { data: productLoadResult } = useGetProductsQuery({
        productGroupId: "ConfigurableProducts",
        includeChildGroups: false
    })
    return productLoadResult?.filter((p) => p.configurable)
}
