import { Typography } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Toolbar from "@mui/material/Toolbar"
import * as React from "react"
import { Outlet } from "react-router-dom"

import { AppStyles } from "./App.styles"
import { useAppSelector } from "./app/hooks"
import { useLogout } from "./app/logout.hooks"
import { ActionBar } from "./common/ActionBar/ActionBar"
import { Breadcrumbs } from "./common/Breadcrumbs"
import { LoadingLayer } from "./common/LoadingLayer/LoadingLayer"
import { HeaderUser } from "./components/Header/User"
import { selectPending } from "./features/ui/uiSlice"

export const Layout = () => {
    const isPending = useAppSelector(selectPending)

    const [doLogout] = useLogout()

    return (
        <>
            <LoadingLayer show={isPending} />
            <Box component="div" sx={AppStyles.wrapper}>
                <AppBar position="sticky" color="primary" sx={AppStyles.AppBar}>
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <Typography
                                variant="h5"
                                fontWeight="bold"
                                noWrap
                                component="span"
                            >
                                ProSet Configurator
                            </Typography>

                            <HeaderUser logoutHandler={doLogout} />
                        </Toolbar>
                    </Container>
                </AppBar>

                <Container maxWidth="xl" sx={AppStyles.content}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: {
                                xs: "column",
                                md: "row"
                            },
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: {
                                xs: "center",
                                md: "space-between"
                            }
                        }}
                    >
                        <Breadcrumbs />
                        <ActionBar />
                    </Box>
                    <Outlet />
                </Container>
            </Box>
        </>
    )
}
