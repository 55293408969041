import Box from "@mui/material/Box"
import React from "react"

import { SalesDocument } from "../../../sets/sets.types"
import { CustomerInformation } from "./CustomerInformation"
import { KitInformation } from "./KitInformation"
import { PotentialData } from "./PotentialData"
import { RegulatoryData } from "./RegulatoryData"

type Props = {
    set: SalesDocument
}

export const GeneralData = ({ set }: Props) => {
    return (
        <Box component="section">
            <KitInformation set={set} />
            <RegulatoryData set={set} />
            <CustomerInformation set={set} />
            <PotentialData set={set} />
        </Box>
    )
}
