import {
    FontIcon,
    Link,
    registerIcons,
    SpinButton,
    Stack,
    StackItem
} from "@fluentui/react"
import { DeleteIcon } from "@fluentui/react-icons-mdl2"
import React from "react"

import { ProductDataProps } from "./BillOfMaterials"
import { BillOfMaterialsRow } from "./BillOfMaterialsRow"

const DELETE_ICON = "DeleteIcon"
registerIcons(
    {
        icons: {
            [DELETE_ICON]: <DeleteIcon />
        }
    },
    { disableWarnings: true }
)

type Props = {
    id: string
    quantity: string
    productData?: ProductDataProps
    onChangeHandler?: (productId: string, quantity: number) => void
    onDeleteHandler?: (productId: string) => void
}

export const BillOfMaterialsContentRow = ({
    id,
    quantity,
    productData,
    onChangeHandler,
    onDeleteHandler
}: Props) => {
    const isMandatory = (productData && productData.mandatory) || false

    const renderIconCell = () => (isMandatory && "LOCK") || "UNLOCK"

    const renderProductCell = () =>
        (productData && (
            <Stack horizontal={false}>
                <StackItem>{productData.name}</StackItem>
                <StackItem style={{ color: "rgb(236, 236, 236)" }}>
                    {productData.articleNumber}
                </StackItem>
            </Stack>
        )) ||
        id

    const renderQuantityCell = () =>
        (!isMandatory && onChangeHandler && (
            <SpinButton
                value={quantity}
                min={1}
                onChange={(event, newQuantity) =>
                    onChangeHandler(id, newQuantity as unknown as number)
                }
                styles={{
                    root: {
                        border: "none",
                        minWidth: "none",
                        maxWidth: "3.5rem"
                    },
                    spinButtonWrapper: {
                        minWidth: "none",
                        "&::after": {
                            border: "none"
                        }
                    },
                    input: {
                        fontSize: "0.75rem",
                        padding: "0 0.25rem 0 0.25rem",
                        minWidth: "none",
                        textOverflow: "none"
                    }
                }}
            />
        )) ||
        quantity

    const renderActionCell = () =>
        (onDeleteHandler && !isMandatory && (
            <Link
                as={"a"} // otherwise this link will be displayed as a button
                onClick={() => onDeleteHandler(id)}
            >
                <FontIcon aria-label="DeleteIcon" iconName={DELETE_ICON} />
            </Link>
        )) || <></>

    return (
        <BillOfMaterialsRow
            icon={renderIconCell()}
            lineItem={renderProductCell()}
            quantity={renderQuantityCell()}
            action={renderActionCell()}
        />
    )
}
