import { Http } from "@encoway/c-services-js-client"
import { SalesService } from "@encoway/sales-api-js-client"

let salesService: SalesService | null = null

export const initSalesService = async (http: Http) => {
    if (salesService) {
        throw new Error("salesService already initialized")
    }
    salesService = await SalesService.create(http, "")
    return salesService
}

export const getSalesService = () => {
    if (!salesService) {
        throw new Error("salesService not initialized")
    }
    return salesService
}
