const WarningStyles = {
    formGrid: {
        mb: 6
    },
    message: {
        mb: 2
    }
}

export { WarningStyles }
