import { IRenderFunction } from "@fluentui/react"
import { Label as FluentLabel } from "@fluentui/react/lib/Label"
import React, { ReactElement } from "react"

import { ParameterTO } from "@encoway/c-services-js-client"
import { Parameter, ParameterProps } from "@encoway/cui-configurator-components"

import { customColors } from "../../themes/styles"

export const BBraunParameter = (props: ParameterProps) => {
    const onRenderLabel: IRenderFunction<ParameterTO> = (
        item?: ParameterTO,
        defaultRenderOption?: (props?: ParameterTO) => ReactElement | null
    ) => {
        if (item && defaultRenderOption) {
            const mandatoryLabel = (
                <FluentLabel>{`${item.translatedName} *`}</FluentLabel>
            )
            return item.mandatory
                ? mandatoryLabel
                : defaultRenderOption(props.data)
        }
        return null
    }

    return (
        <Parameter
            {...props}
            onRenderLabel={onRenderLabel}
            styles={{
                root: {
                    "label.ms-Label": {
                        color: customColors.almostBlack,
                        fontSize: "0.75rem",
                        fontWeight: 400,
                        lineHeight: "1.125rem"
                    }
                },
                visible: {
                    marginTop: "3.75rem",
                    marginBottom: "0.2rem"
                }
            }}
        />
    )
}
