import { useBoolean } from "@fluentui/react-hooks"
import { DefaultButton } from "@fluentui/react/lib/Button"
import React from "react"

import { ProductsFilterDialog } from "./ProductsFilterDialog"

export type ProductsFilterProps = {
    productGroupId: string
}

export const ProductsFilter = ({ productGroupId }: ProductsFilterProps) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)

    return (
        <>
            <DefaultButton
                iconProps={{ iconName: "FilterSolid" }}
                text={"Filter"}
                onClick={toggleHideDialog}
                style={{
                    border: "0"
                }}
            />
            <ProductsFilterDialog
                productGroupId={productGroupId}
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
            />
        </>
    )
}
