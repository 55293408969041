import { Button, Divider } from "@mui/material"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import React, { useState } from "react"

import { TabsStyles } from "./Tabs.styles"

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={TabsStyles.tabpanel}>{children}</Box>}
        </div>
    )
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    }
}

interface ComponentInterface {
    tabs: any[]
    selectedTabIndex?: number
    navigation?: boolean
}

export default function Component({
    tabs,
    selectedTabIndex,
    navigation
}: ComponentInterface) {
    const [selectedTab, setSelectedTab] = useState(selectedTabIndex || 0)

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue)
    }

    return (
        <Box component="section">
            <Box sx={TabsStyles.tabWrapper}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="Update Set Parameters"
                    textColor="primary"
                    indicatorColor="primary"
                >
                    {tabs.map((tab, ix) => {
                        return (
                            <Tab
                                key={ix}
                                sx={TabsStyles.tab}
                                label={tab.title}
                                {...a11yProps(ix)}
                            />
                        )
                    })}
                    {/* <Tab sx={TabsStyles.tab} label="General Data" {...a11yProps(0)} />
          <Tab sx={TabsStyles.tab} label="Item List" {...a11yProps(1)} />
          <Tab sx={TabsStyles.tab} label="Pricing" {...a11yProps(2)} />
          <Tab sx={TabsStyles.tab} label="Warning" {...a11yProps(3)} /> */}
                </Tabs>
            </Box>
            {tabs.map((tab, ix) => {
                return (
                    <TabPanel key={ix} value={selectedTab} index={ix}>
                        {tab.component}
                        {navigation ? (
                            <Box component="span" sx={TabsStyles.tabFooter}>
                                <Divider />
                                {ix > 0 ? (
                                    <Button
                                        variant="outlined"
                                        color="neutral"
                                        sx={TabsStyles.tabNavBack}
                                        onClick={() => {
                                            setSelectedTab(ix - 1)
                                        }}
                                    >
                                        Previous
                                    </Button>
                                ) : (
                                    <></>
                                )}
                                {ix < tabs.length - 1 ? (
                                    <Button
                                        variant="outlined"
                                        color="neutral"
                                        sx={TabsStyles.tabNavNext}
                                        onClick={() => {
                                            setSelectedTab(ix + 1)
                                        }}
                                    >
                                        Next
                                    </Button>
                                ) : (
                                    <></>
                                )}
                            </Box>
                        ) : (
                            <></>
                        )}
                    </TabPanel>
                )
            })}
        </Box>
    )
}
