import { Stack } from "@fluentui/react"
import React, { useEffect, useState } from "react"

import { ImageButtonProps } from "@encoway/cui-configurator-components"

import { BillOfMaterialsContentRow } from "./BillOfMaterialsContentRow"
import { BillOfMaterialsRow } from "./BillOfMaterialsRow"
import { useCatalogCompositionBomHelper } from "./catalogComposition.hooks"

export type ProductDataProps = {
    articleNumber: string
    name: string
    mandatory: boolean
}

type ProductsProps = {
    [key: string]: ProductDataProps
}

type Props = {
    productGroupId: string
} & ImageButtonProps

export const BillOfMaterials = (props: Props) => {
    const [products, setProducts] = useState<ProductsProps>({})
    const { getBomProducts, changeProductInBom, deleteProductInBom } =
        useCatalogCompositionBomHelper(props.onValueChanged)
    const bomProducts = getBomProducts(props.data)
    const prepareProducts = (): ProductsProps =>
        props.data.values?.reduce(
            (acc, product) => ({
                ...acc,
                [product.value]: {
                    articleNumber: product.value,
                    name: product.translatedValue,
                    mandatory: product.possibleDefault
                }
            }),
            {}
        ) ?? {}

    useEffect(() => {
        setProducts(prepareProducts())
    }, [])

    return (
        <Stack
            styles={{
                root: {
                    backgroundColor: "rgb(255, 255, 255)",
                    color: "rgb(99, 99, 99)",
                    transition:
                        "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    borderRadius: "8px",
                    boxShadow:
                        "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
                    width: "100%",
                    overflowX: "auto",
                    "& .ms-StackItem": {
                        fontSize: "0.75rem",
                        color: "rgb(99, 99, 99)"
                    },
                    "& > .ms-StackItem:not(:first-child)": {
                        borderTop: "1px solid rgb(224, 224, 224)"
                    },
                    "& > .ms-StackItem:first-child": {
                        fontWeight: "bold"
                    }
                }
            }}
        >
            <BillOfMaterialsRow
                lineItem={"Line Item"}
                quantity={"Qty."}
                styles={{
                    lineItemCell: { fontWeight: "bold" },
                    quantityCell: { fontWeight: "bold" }
                }}
            />
            {Object.entries(bomProducts).map(([id, quantity]) => (
                <BillOfMaterialsContentRow
                    key={`bomRow-${id}`}
                    id={id}
                    quantity={`${quantity}`}
                    productData={products[id]}
                    onDeleteHandler={(productId) =>
                        deleteProductInBom(props.data, productId)
                    }
                    onChangeHandler={(productId, quantity) =>
                        changeProductInBom(props.data, productId, quantity)
                    }
                />
            ))}
        </Stack>
    )
}
