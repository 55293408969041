import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import React from "react"

import AppLogo from "../components/AppLogo"
import { LoginStyles } from "./Login.styles"

export default function ResetPassword() {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        console.log({
            email: data.get("email")
        })
    }

    return (
        <Box sx={LoginStyles.page}>
            <AppLogo />
            <Typography component="h1" variant="h5">
                Reset Password
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={LoginStyles.form}
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={LoginStyles.submitBtn}
                >
                    Reset Password
                </Button>
                <Grid container>
                    <Grid item xs></Grid>
                    <Grid item>
                        <Link href="/login" variant="body2">
                            {"Back to login."}
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
