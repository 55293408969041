import { useAppSelector } from "../../../../app/hooks"
import { useUpdateSalesDocumentMutation } from "../../../../services/salesDocumentAPI"
import { SalesDocument } from "../../../sets/sets.types"
import { selectPropertyValue } from "../../setSlice"

const RESET_SELECTION_OPTION = "application.selection.reset"

export const useBudiProperties = (set: SalesDocument) => {
    const riskClass = useAppSelector(
        selectPropertyValue("maximumRiskClass", set)
    )
    const intendedUsagesAsString = useAppSelector(
        selectPropertyValue("intendedUsages", set)
    )
    const intendedUsage = useAppSelector(
        selectPropertyValue("intendedUsage", set)
    )
    const budiName = useAppSelector(selectPropertyValue("budiName", set))

    const [updateSalesDocument] = useUpdateSalesDocumentMutation()

    const setIntendedUsage = async (intendedUsage: string) => {
        await updateSalesDocument({
            intendedUsage:
                intendedUsage === RESET_SELECTION_OPTION ? "" : intendedUsage
        })
    }

    const intendedUsages: string[] = intendedUsagesAsString
        ? [RESET_SELECTION_OPTION, ...intendedUsagesAsString.split(",")] // add a "reset selection" option if there are options
        : []

    return {
        riskClass,
        intendedUsages,
        setIntendedUsage,
        intendedUsage,
        budiName
    }
}
