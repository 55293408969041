import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import DeleteIcon from "@mui/icons-material/Delete"
import ImportExportIcon from "@mui/icons-material/ImportExport"
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined"
import Stack from "@mui/material/Stack"
import {
    DataGrid,
    GridActionsCellItem,
    GridColumns,
    GridRenderEditCellParams,
    GridRowId,
    GridRowParams
} from "@mui/x-data-grid"
import { t } from "i18next"
import React from "react"

import { LineItem } from "@encoway/sales-api-js-client"

import PopupNavigation from "../../../../components/PopupNavigation"
import NotesButton from "../../../../components/Set/Configuration/NotesButton"
import StatusIcon from "../../../../components/StatusIcon"
import { TableListStyles } from "../../../../components/TableList.styles"
import TableQuickSearchToolBar from "../../../../components/TableQuickSearchToolBar"
import { useGetLineItemLeafsQuery } from "../../../../services/lineItemAPI"
import MockupStore from "../../../../theme/mockup-data"

export const ItemList = () => {
    const { data } = useGetLineItemLeafsQuery()

    const items = data ?? []

    // Currently unused
    const deleteAction = React.useCallback(
        (id: GridRowId) => () => {
            const lineItem = items.find(
                (lineItem: LineItem) => lineItem.lineItemId === id
            )
            if (lineItem) {
                console.log(`Delete configuration ${lineItem.properties.label}`)
            }
        },
        [items]
    )

    // Currently unused
    const duplicateAction = React.useCallback(
        (id: GridRowId) => () => {
            const lineItem = items.find(
                (lineItem: LineItem) => lineItem.lineItemId === id
            )
            if (lineItem) {
                console.log(
                    `Duplicate configuration ${lineItem.properties.label}`
                )
            }
        },
        [items]
    )

    // Currently unused
    const notesAction = React.useCallback(
        (id: GridRowId) => () => {
            const lineItem = items.find(
                (lineItem: LineItem) => lineItem.lineItemId === id
            )
            if (lineItem) {
                console.log(
                    `Edit note for configuration ${lineItem.properties.label}`
                )
            }
        },
        [items]
    )

    const columns = React.useMemo<GridColumns<LineItem>>(
        () => [
            {
                field: "articleNumber",
                headerName: `${t("application.common.articleNo")}`,
                disableColumnMenu: true,
                hideable: false,
                width: 160
            },
            {
                field: "status",
                headerName: `${t("application.common.status")}`,
                disableColumnMenu: true,
                hide: true,
                width: 120,
                renderCell: (params: GridRenderEditCellParams<string>) => {
                    return <StatusIcon status={params.value} withLabel={true} />
                }
            },
            {
                field: "name",
                headerName: `${t("application.common.name")}`,
                disableColumnMenu: true,
                flex: 2,
                minWidth: 200
            },
            {
                field: "quantity",
                headerName: `${t("application.common.quantity")}`,
                disableColumnMenu: true,
                flex: 2,
                minWidth: 200
            },
            {
                field: "edit-note",
                headername: `${t("application.common.editNote")}`,
                disableColumnMenu: true,
                type: "actions",
                width: 40,
                hideable: false,
                hideSortIcons: true,
                cellClassName: "is--actions",
                headerClassName: "is--actions",
                getActions: (params: GridRowParams) => [
                    <GridActionsCellItem
                        key="some-grid-actions-cell-item-key-1"
                        icon={
                            <NotesButton
                                note={
                                    MockupStore.getConfigurationById(
                                        String(params.id)
                                    )?.note
                                }
                            />
                        }
                        className="is--note"
                        label={t("application.common.documents")}
                        onClick={notesAction(params.id)}
                    />
                ]
            },
            {
                field: "note",
                headerName: `${t("application.common.note")}`,
                flex: 1,
                minWidth: 160,
                hide: true
            },
            {
                field: "actions",
                headerName: "",
                hideable: false,
                hideSortIcons: true,
                sortable: false,
                filterable: false,
                width: 60,
                cellClassName: "is--actions",
                headerClassName: "is--actions",
                disableColumnMenu: false,
                renderCell: (params: GridRenderEditCellParams<string>) => {
                    const popUpNavigationItems = [
                        {
                            icon: <ContentCopyIcon fontSize="small" />,
                            onClick: duplicateAction(params.id),
                            text: `${t("application.common.duplicate")}`
                        },
                        {
                            icon: <DeleteIcon fontSize="small" />,
                            onClick: deleteAction(params.id),
                            text: `${t("application.common.delete")}`
                        }
                    ]
                    return (
                        <PopupNavigation
                            popUpNavigationItems={popUpNavigationItems}
                        />
                    )
                }
            }
        ],
        [deleteAction, duplicateAction, notesAction]
    )

    return (
        <DataGrid
            sx={TableListStyles.dataGrid}
            rows={items.map((lineItem) => ({
                // map to auto-resolve column names
                ...lineItem,
                id: lineItem.lineItemId,
                articleNumber: lineItem.properties.name,
                name: lineItem.properties.label,
                edit_note: lineItem.properties.edit_note,
                quantity: lineItem.properties.newAmount
            }))}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50]}
            autoHeight={true}
            disableSelectionOnClick
            components={{
                ColumnUnsortedIcon: () => <ImportExportIcon fontSize="small" />,
                ColumnMenuIcon: () => (
                    <ViewColumnOutlinedIcon fontSize="small" />
                ),
                NoRowsOverlay: () => (
                    <Stack
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                    >
                        No items found
                    </Stack>
                ),
                NoResultsOverlay: () => (
                    <Stack
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                    >
                        Set filter returns no results
                    </Stack>
                ),
                Toolbar: () => <TableQuickSearchToolBar />
            }}
            componentsProps={{
                panel: {
                    sx: {
                        "& .MuiDataGrid-panelHeader": {
                            display: "none"
                        },
                        "& .MuiDataGrid-panelFooter": {
                            display: "none"
                        }
                    }
                },
                pagination: {
                    SelectProps: {
                        variant: "outlined",
                        size: "small"
                    },
                    showFirstButton: true,
                    showLastButton: true
                }
            }}
        />
    )
}
