import { getTheme } from "@fluentui/react"
import * as React from "react"

import { Tab, TabProps } from "@encoway/cui-configurator-components"

import { borderRadius, customColors } from "../../themes/styles"

export const BBraunTab = (props: TabProps) => {
    const theme = getTheme()
    return (
        <Tab
            {...props}
            styles={{
                tab: {
                    backgroundColor: theme.palette.white,
                    borderRadius: `${borderRadius.borderRadius} ${borderRadius.borderRadius} 0px 0px`,
                    padding: "0.75rem 1rem",
                    minWidth: "5.625rem",
                    ":hover, :active": {
                        backgroundColor: customColors.lightGray
                    }
                },
                notReady: {
                    backgroundColor: theme.palette.white,
                    color: customColors.mediumGray,
                    ":hover, :active": {
                        backgroundColor: customColors.lightGray
                    }
                },
                selected: {
                    ".ms-StackItem > span": {
                        color: customColors.darkGreen
                    },
                    borderBottom: `3px solid ${customColors.darkGreen}`,
                    ":hover, :active": {
                        backgroundColor: customColors.lightGreen
                    }
                },
                text: {
                    color: customColors.mediumGray,
                    fontWeight: "bold"
                }
            }}
        />
    )
}
