import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { SalesDocument } from "../../../sets/sets.types"
import {
    removeChange,
    selectPropertyValue as originalSelectPropertyValue,
    setChange
} from "../../setSlice"

export const useProperties = (set: SalesDocument) => {
    const dispatch = useAppDispatch()
    const selectPropertyValue = (propertyId: string) =>
        useAppSelector(originalSelectPropertyValue(propertyId, set))

    const setPropertyValue = (propertyId: string, newValue: string) => {
        const oldValue = set.properties[propertyId]
        newValue !== (oldValue ?? "")
            ? dispatch(setChange({ propertyId, propertyValue: newValue }))
            : dispatch(removeChange(propertyId))
    }

    return {
        selectPropertyValue,
        setPropertyValue
    }
}
