import Box from "@mui/material/Box"
import { SxProps } from "@mui/system"
import React from "react"

import LogoImage from "../assets/logo.svg"
import { AppLogoStyles } from "./AppLogo.styles"

interface ComponentInterface {
    sx?: SxProps
}

export default function AppLogo({ sx }: ComponentInterface) {
    let styles: SxProps = AppLogoStyles.logo

    if (sx) {
        styles = { ...styles, ...sx }
    }

    return (
        <Box sx={styles} component="img" alt="Company Logo" src={LogoImage} />
    )
}
