import { styled } from "@mui/material"
import Box from "@mui/material/Box"
import { Params } from "@remix-run/router"
import * as React from "react"
import { useMatches } from "react-router-dom"

import { ConfigurationActions } from "../../features/configuration/ConfigurationActions"
import { SetActions } from "./SetActions"

// used by "useMatches" call which does not have an explicit type.
// that's why we define it here
type Match = {
    id: string
    pathname: string
    params: Params<string>
    data: unknown
    handle?: {
        actionBarActions?: string
    }
}

const useTypedMatches = (): Match[] => {
    return useMatches() as unknown as Match[]
}

const StyledBox = styled(Box)({
    display: "flex",
    whiteSpace: "nowrap",
    paddingTop: 0,
    paddingBottom: "2rem",

    "> button, > a ": {
        marginRight: "0.5rem",
        marginLeft: "0.5rem"
    },
    "> *:last-child": {
        marginRight: 0
    }
})

export const ActionBar = () => {
    const matches = useTypedMatches()
    const actionBarActions =
        matches[matches.length - 1].handle?.actionBarActions
    if (!actionBarActions) {
        return <></>
    }

    return (
        <StyledBox>
            {actionBarActions === "set" && <SetActions />}
            {actionBarActions === "configuration" && (
                <ConfigurationActions
                    isDirty={true}
                    isValid={true} // TODO: Insert correct boolean -> see Configuration.tsx line 82 (currentConfigurationIsValid)
                />
            )}
        </StyledBox>
    )
}
