import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectProps } from "@mui/material/Select"
import React from "react"
import { useTranslation } from "react-i18next"

type Props = {
    id: string
    label: string
    menuItems: string[]
}

export const SelectField = ({
    id,
    label,
    menuItems,
    ...selectProps
}: Props & SelectProps) => {
    const { t } = useTranslation()
    return (
        <FormControl fullWidth margin="normal">
            <InputLabel id={id + "Label"} shrink={true}>
                {label}
            </InputLabel>
            <Select
                variant="outlined"
                size="small"
                id={id}
                label={label}
                labelId={id + "Label"}
                {...selectProps}
            >
                {menuItems.map((key) => (
                    <MenuItem key={`${id}-MenuItem-${key}`} value={key}>
                        {t(key)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
