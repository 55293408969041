import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { unstable_useBlocker as useBlocker } from "react-router-dom"

import { clearChanges } from "../features/set/setSlice"
import { useAppDispatch } from "./hooks"

export const useCallbackPrompt = (when: boolean) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [showPrompt, setShowPrompt] = useState(false)
    const [newLocation, setNewLocation] = useState<any>(null)
    const [confirmedNavigation, setConfirmedNavigation] = useState(false)

    const cancelNavigation = useCallback(() => {
        setShowPrompt(false)
    }, [])

    // handle blocking when user click on another route prompt will be shown
    const handleBlockedNavigation = useBlocker(
        useCallback(
            (location: any) => {
                // in if condition we are checking next location and current location are equals or not
                if (
                    when &&
                    !confirmedNavigation &&
                    location.nextLocation.pathname !==
                        location.currentLocation.pathname &&
                    !location.nextLocation.pathname.includes(
                        location.currentLocation.pathname
                    )
                ) {
                    setShowPrompt(true)
                    setNewLocation(location.nextLocation)
                    return true
                }
                return false
            },
            [confirmedNavigation, when]
        )
    )

    const confirmNavigation = useCallback(() => {
        setShowPrompt(false)
        setConfirmedNavigation(true)
    }, [])

    useEffect(() => {
        if (handleBlockedNavigation.state === "blocked") {
            handleBlockedNavigation.reset()
        }

        if (confirmedNavigation && newLocation) {
            dispatch(clearChanges())
            navigate(newLocation.pathname)
        }
    }, [confirmedNavigation, newLocation])

    return { showPrompt, confirmNavigation, cancelNavigation }
}
