import {
    AnyAction,
    Dispatch,
    isFulfilled,
    isPending,
    isRejectedWithValue,
    MiddlewareAPI
} from "@reduxjs/toolkit"

import { setFetchError, setPending } from "../features/ui/uiSlice"

const handleRejectedRequests =
    (api: MiddlewareAPI) => (next: Dispatch) => (action: any) => {
        if (isRejectedWithValue(action)) {
            api.dispatch(setFetchError(action.payload.data))
        }
        return next(action)
    }

const handlePendingRequests =
    (api: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
        // reducer handles that there might be multiple pending requests
        if (isPending(action)) {
            api.dispatch(setPending(true))
        } else if (isFulfilled(action)) {
            // only set to false if the action was fulfilled. redux also creates "requests" with state "rejected"
            // if the request was skipped because there was either already a request in flight or already a value in cache
            // this leads to more "false" states than "true" and the reducer's counter gets useless
            api.dispatch(setPending(false))
        }
        return next(action)
    }

export const customMiddleware = {
    handleRejectedRequests,
    handlePendingRequests
}
