import theme from "../theme"

const TableListStyles = {
    table: {
        minWidth: 650
    },
    dataGrid: {
        border: "none",
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus":
            {
                outline: "none"
            },
        ".MuiDataGrid-main": {
            borderColor: theme.palette.neutral.light,
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 1,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        },
        ".MuiDataGrid-columnHeaders": {
            background: theme.palette.neutral.light
        },
        ".MuiDataGrid-columnHeader:not(.is--actions) .MuiDataGrid-columnHeader:hover":
            {
                background: theme.palette.text.disabled
            },
        ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.body2.fontSize,
            color: theme.palette.text.secondary
        },
        ".MuiDataGrid-iconButtonContainer": {
            ml: "auto"
        },
        ".MuiDataGrid-columnHeader.is--actions .MuiDataGrid-menuIcon": {
            visibility: "visible",
            width: "auto",
            mr: "auto",
            ml: "auto"
        },
        ".MuiDataGrid-columnHeader.is--actions .MuiDataGrid-columnHeaderTitleContainer":
            {
                display: "none"
            },
        ".MuiDataGrid-columnSeparator": {
            display: "none"
        },
        ".MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon path": {
            color: theme.palette.primary.main
        },
        ".MuiDataGrid-footerContainer": {
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: theme.palette.neutral.light,
            borderRadius: 1,
            borderTop: "none",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
        }
    }
}

export { TableListStyles }
