import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import { t } from "i18next"
import React from "react"
import { NavLink } from "react-router-dom"

import type { UserInterface } from "../../utils/interfaces"
import { UserStyles } from "./User.styles"

interface ComponentInterface {
    user?: UserInterface
    logoutHandler: () => void
}

export const HeaderUser = ({ user, logoutHandler }: ComponentInterface) => {
    const userEmail = user?.email ? user.email : ""

    return (
        <Box component="div" sx={UserStyles.wrapper}>
            <Tooltip title={`${t("application.buttons.logout")} ${userEmail}`}>
                <IconButton
                    sx={UserStyles.icon}
                    size="large"
                    component={NavLink}
                    color="inherit"
                    to={""}
                    onClick={() => logoutHandler()}
                >
                    <AccountCircleIcon />
                </IconButton>
            </Tooltip>
        </Box>
    )
}
