import { GridSortModel } from "@mui/x-data-grid"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { Constants } from "@encoway/sales-api-js-client"

import { RootState } from "../../app/store"
import { ListOptions, SortDirection } from "./sets.types"

type SetsState = {
    listOptions: ListOptions
}

const initialState: SetsState = {
    listOptions: {
        page: 0,
        rowsPerPage: 10,
        sortField: "quote_created_at",
        sortDirection: "DESC"
    }
}

const setsSlice = createSlice({
    name: "sets",
    initialState,
    reducers: {
        setPage: (state, action: PayloadAction<number>) => {
            state.listOptions.page = action.payload
        },
        setRowsPerPage: (state, action: PayloadAction<number>) => {
            state.listOptions.page = 0
            state.listOptions.rowsPerPage = action.payload
        },
        setSorting: (state, action: PayloadAction<GridSortModel>) => {
            if (action.payload.length === 0) {
                state.listOptions.sortField = initialState.listOptions.sortField
                state.listOptions.sortDirection =
                    initialState.listOptions.sortDirection
            } else {
                state.listOptions.sortField = action.payload[0].field
                if (action.payload[0].sort) {
                    state.listOptions.sortDirection =
                        action.payload[0].sort.toUpperCase() as SortDirection
                } else {
                    state.listOptions.sortDirection = Constants.ASC
                }
            }
        }
    }
})

export const { setRowsPerPage, setPage, setSorting } = setsSlice.actions

export const selectListOptions = (state: RootState): ListOptions =>
    state.sets.listOptions

export default setsSlice.reducer
