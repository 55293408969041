import TextField, { TextFieldProps } from "@mui/material/TextField"
import React from "react"

type Props = {
    id: string
    label: string
    type?: "text" | "number" | "date"
}

export const InputField = ({
    id,
    label,
    type,
    ...textFieldProps
}: Props & TextFieldProps) => {
    return (
        <TextField
            fullWidth
            type={type ?? "text"}
            margin="normal"
            id={id}
            label={label}
            name={id}
            autoComplete={id}
            variant="outlined"
            size="small"
            {...textFieldProps}
            InputLabelProps={{
                ...textFieldProps.InputLabelProps,
                shrink: true
            }}
        />
    )
}
