import { getTheme, mergeStyles } from "@fluentui/react"
import { CommandBarButton } from "@fluentui/react/lib/Button"
import { List } from "@fluentui/react/lib/List"
import React from "react"

import { ProductGroup } from "@encoway/sales-api-js-client"

import { customColors } from "../../../themes/styles"

type Props = {
    productGroups: ProductGroup[]
    selectedProductGroupId: string
    onProductGroupSelection: (productGroupId: string) => void
}

const theme = getTheme()
const buttonStyles = (isSelected: boolean) => {
    const colors = customColors.productGroups
    return {
        root: {
            padding: "0.625rem 0.75rem",
            backgroundColor: isSelected ? colors.selected : theme.palette.white,
            textAlign: "left",
            fontSize: "0.875rem"
        },
        rootHovered: {
            backgroundColor: isSelected
                ? colors.selectedHovered
                : colors.hovered
        },
        icon: {
            width: "1.125rem"
        }
    }
}

export const ProductGroups = (props: Props) => {
    const { productGroups, selectedProductGroupId, onProductGroupSelection } =
        props

    const onRenderCell = (productGroup?: ProductGroup) => {
        const isSelected =
            selectedProductGroupId === productGroup?.productGroupId
        return (
            productGroup && (
                <CommandBarButton
                    styles={buttonStyles(isSelected)}
                    iconProps={{
                        iconName: isSelected
                            ? "FabricFolderFill"
                            : "FabricFolder"
                    }}
                    text={productGroup.characteristics.label}
                    onClick={() =>
                        onProductGroupSelection(productGroup.productGroupId)
                    }
                />
            )
        )
    }

    return (
        <List
            className={mergeStyles({
                "& .ms-List-cell": {
                    display: "flex",
                    flexDirection: "column"
                }
            })}
            items={[...productGroups]} // we need to trigger re-rendering of fluent List component when the selection changed
            onRenderCell={onRenderCell}
        />
    )
}
