import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown"
import React, { FormEvent, useState } from "react"

import { customColors } from "../../../../themes/styles"
import {
    FilterCharacteristic,
    FilterCharacteristicValueConcrete,
    FilterSelection
} from "./filter.types"

const DESELECT_VALUE = "NO_FILTERING"

export type ProductsFilterControlProps = {
    selectedFilters: FilterSelection
    filterCharacteristic: FilterCharacteristic
    onFilterChange: (characteristicId: string, value: any) => void
}

export const ProductsFilterCharacteristic = ({
    selectedFilters,
    filterCharacteristic,
    onFilterChange
}: ProductsFilterControlProps) => {
    const [selectedKey, setSelectedKey] = useState<string | number | null>(
        selectedFilters[filterCharacteristic.characteristicId] ?? null
    )

    if (
        !filterCharacteristic.possibleValues ||
        filterCharacteristic.possibleValues.length === 0
    ) {
        return null
    }

    const onChange = (
        event: FormEvent<HTMLDivElement>,
        option?: IDropdownOption
    ): void => {
        if (!option?.key || option.key === DESELECT_VALUE) {
            setSelectedKey(null)
            onFilterChange(filterCharacteristic.characteristicId, null)
        } else {
            setSelectedKey(option.key)
            onFilterChange(filterCharacteristic.characteristicId, option?.key)
        }
    }

    const unselectOption: IDropdownOption = {
        key: DESELECT_VALUE,
        text: "-"
    }

    const filterOptions: IDropdownOption[] = filterCharacteristic.possibleValues
        // Interval characteristic values currently not handled because of complexity
        .filter((possibleValue) => !possibleValue.interval)
        .map(
            (possibleValue) =>
                possibleValue as FilterCharacteristicValueConcrete
        )
        .map((possibleValue) => ({
            key: possibleValue.value,
            text: possibleValue.label ?? possibleValue.value
        }))

    return (
        <Dropdown
            label={filterCharacteristic.label}
            placeholder="Select an option"
            selectedKey={selectedKey}
            onChange={onChange}
            options={[unselectOption, ...filterOptions]}
            styles={{
                root: {
                    marginBottom: "1rem"
                },
                dropdown: {
                    ":focus::after": {
                        display: "none"
                    }
                },
                title: {
                    backgroundColor: customColors.lightGray,
                    minHeight: "2.5rem",
                    lineHeight: "2.5rem",
                    border: "0",
                    borderRadius: "8px",
                    paddingLeft: "1rem"
                },
                caretDownWrapper: {
                    marginTop: "0.25rem",
                    marginRight: "0.25rem"
                },
                dropdownItem: {
                    ":hover, :active, :focus": {
                        color: "#fff",
                        backgroundColor: customColors.almostBlack
                    }
                }
            }}
        />
    )
}
