import { Breadcrumbs as MuiBreadcrumbs, styled } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import React from "react"
import { useParams } from "react-router-dom"

import { useGetCurrentSalesDocument } from "../app/salesService.hooks"
import AppNavItem from "../components/AppNavItem"
import StatusIcon from "../components/StatusIcon"
import getPageTitleByBreadcrumbItems from "../utils/getPageTitleByBreadcrumbItems"
import type { PageInterface } from "../utils/interfaces"
import useDocumentTitle from "../utils/useDocumentTitle"
import useGetBreadcrumbPagesByRoute from "../utils/useGetBreadcrumbPagesByRoute"

const StyledBox = styled(Box)({
    whiteSpace: "nowrap",
    paddingTop: 0,
    paddingBottom: "2rem"
})

const styles = {
    breadcrumbs: {},
    crumbStatus: {
        ml: 1,
        display: "inline-block",
        verticalAlign: "baseline"
    },
    crumbName: {
        display: "inline-block",
        whiteSpace: "nowrap",
        maxWidth: {
            xs: "20vw",
            md: "9vw",
            lg: "20vw"
        },
        overflow: "hidden",
        textOverflow: "ellipsis",
        verticalAlign: "middle"
    },
    crumbAppendix: {
        display: "inline-block",
        whiteSpace: "nowrap",
        marginLeft: "0.33em",
        verticalAlign: "middle",
        "&::before": {
            content: '"("'
        },
        "&::after": {
            content: '")"'
        }
    }
}

export const Breadcrumbs = () => {
    const { configurationId } = useParams()
    const salesDocument = useGetCurrentSalesDocument()

    const breadcrumbItems = useGetBreadcrumbPagesByRoute(
        location.hash.substring(1), // remove '#' at the start
        {
            set: salesDocument,
            configuration: configurationId
        }
    )

    useDocumentTitle(getPageTitleByBreadcrumbItems(breadcrumbItems))

    if (!breadcrumbItems.length) {
        return <></>
    }

    return (
        <StyledBox role="presentation">
            <MuiBreadcrumbs aria-label="breadcrumb" sx={styles.breadcrumbs}>
                {breadcrumbItems.map((page: PageInterface, ix: number) => {
                    if (ix < breadcrumbItems.length - 1 && page.route) {
                        return (
                            <AppNavItem
                                key={ix}
                                color="inherit"
                                to={page.route}
                                onClick={page.onClick}
                            >
                                <Box
                                    component="span"
                                    sx={styles.crumbName}
                                    title={page.name}
                                >
                                    {page.name}
                                </Box>
                                {page.appendix ? (
                                    <Box
                                        component="span"
                                        sx={styles.crumbAppendix}
                                    >
                                        {page.appendix}
                                    </Box>
                                ) : (
                                    <></>
                                )}
                            </AppNavItem>
                        )
                    } else {
                        return (
                            <Typography key={ix} color="text.primary">
                                <Box
                                    component="span"
                                    sx={styles.crumbName}
                                    title={page.name}
                                >
                                    {page.name}
                                </Box>
                                {page.appendix ? (
                                    <Box
                                        component="span"
                                        sx={styles.crumbAppendix}
                                    >
                                        {page.appendix}
                                    </Box>
                                ) : (
                                    <></>
                                )}
                                {page.status ? (
                                    <StatusIcon
                                        sx={styles.crumbStatus}
                                        status={page.status}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Typography>
                        )
                    }
                })}
            </MuiBreadcrumbs>
        </StyledBox>
    )
}
