const AppStyles = {
    wrapper: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        pb: 6
    },
    content: {
        mt: 2
    },
    AppBar: {
        boxShadow: "none",
        borderBottom: "1px solid grey",
        borderBottomColor: "grey.300"
    }
}

export { AppStyles }
