const TableQuickSearchToolbarStyles = {
    tableTop: {
        mb: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "stretch",
        ".MuiTextField-root": {
            pb: 0
        }
    },
    tableTopOptions: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "*:not(:first-of-type)": {
            ml: 2
        }
    },
    search: {
        ".MuiOutlinedInput-root": {
            backgroundColor: "transparent",
            ".MuiOutlinedInput-notchedOutline": {
                borderColor: "text.disabled"
            }
        }
    }
}

export { TableQuickSearchToolbarStyles }
