import { Stylesheet } from "@fluentui/react/lib/Styling"
import i18next from "i18next"
import { merge } from "lodash"
import React from "react"

import {
    IAppSettings,
    ThemedApplication,
    translations as tconf
} from "@encoway/cui-application-components"
import { translations as tapp } from "@encoway/cui-configurator-components"

import { initComponents } from "./components"
import { customTheme } from "./themes/theme"
import { translations as customTranslations } from "./translations"

initComponents()

Stylesheet.getInstance().setConfig({ namespace: "encoway" })

type Props = {
    configurationId?: string
    token: () => string
}

export const CUI = ({ configurationId, token }: Props) => {
    return (
        <ThemedApplication
            theme={customTheme()}
            translations={merge({}, tapp, tconf, customTranslations)}
            appSettings={
                {
                    locale: i18next.language,
                    oauth2: {
                        applicationId: "bbraun-proset-sales",
                        oath2ServerAuthenticationUrl:
                            window.sso.authorizationEndpointUrl
                    },
                    configuration: {
                        baseUrl: "",
                        settings: {},
                        options: {
                            configurationId,
                            articleName: "Epidural_Perifix",
                            linkedConfiguration: "true"
                        },
                        token,
                        includeCredentials: true
                    }
                } as IAppSettings
            }
        />
    )
}
