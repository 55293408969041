import { SearchBox } from "@fluentui/react"
import React from "react"

import { borderRadius, customColors } from "../../../themes/styles"

type Props = {
    placeholder: string
}

const searchBoxStyles = {
    root: {
        backgroundColor: customColors.lightGray,
        borderRadius: borderRadius.borderRadius,
        height: "2.5rem",
        fontSize: "1rem",
        marginBottom: "1.5rem",
        "&:after": {
            display: "none"
        }
    }
}

export const SearchInputField = (props: Props) => {
    const { placeholder } = props

    return (
        <SearchBox
            styles={searchBoxStyles}
            placeholder={placeholder}
            showIcon={true}
        />
    )
}
