import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "../../app/store"

type FetchError = {
    message?: string
    exceptionClass: string
}

type UIState = {
    pendingCount: number
    fetchError: FetchError | null
}

const initialState: UIState = {
    fetchError: null,
    pendingCount: 0
}

const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        setFetchError: (state, action: PayloadAction<FetchError>) => {
            state.fetchError = action.payload
        },
        setPending: (state, action: PayloadAction<boolean>) => {
            const { payload: pending } = action
            // there might be multiple requests running, so we use a counter to make sure we consider them all
            if (pending) {
                state.pendingCount++
            } else {
                state.pendingCount--
            }
        }
    }
})

export default uiSlice.reducer

export const { setFetchError, setPending } = uiSlice.actions

export const selectFetchError = (state: RootState) => state.ui.fetchError
export const selectPending = (state: RootState) => state.ui.pendingCount !== 0
